import { useMutation } from '@tanstack/react-query';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import CloudVPSAPI from 'pages/ServiceManagement/CloudVPS/apis';
import {
  PRICE_PERIOD_TEXT,
  SERVICE_TYPE,
} from 'pages/ServiceManagement/CloudVPS/constants';
import { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tooltip, formatCurrency, notification } from 'tera-dls';
import ModalSummary from '../../../modals/ModalSummary';
import { SERVICE_CLOUD_VPS_URL } from '../../../url';

interface IFromSummaryProps {
  form: UseFormReturn<any>;
}

const FormSummary = ({ form }: IFromSummaryProps): JSX.Element => {
  const [isOpenModalSummary, setIsOpenModalSummary] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const price = Number(
    useMemo(
      () => form.watch('product').prices[form.watch('period')],
      [form.getValues()],
    ),
  );

  const pricePeriod = useMemo(
    () => PRICE_PERIOD_TEXT[form.watch('period')],
    [form.getValues()],
  );

  const PeriodOptions = useMemo(
    () =>
      Object.keys(form.watch('product').prices).map((priceKey) => ({
        value: priceKey,
        label: PRICE_PERIOD_TEXT[priceKey],
      })),
    [form.getValues()],
  );

  const PaymentTodayTooltip = () => {
    return (
      <div className="flex flex-col">
        <div className="border-b-[1px] pb-2 flex flex-col gap-2">
          Thanh toán hôm nay:
          <span className="font-[500]">{formatCurrency(price)}</span>
        </div>
        <div className="pt-2 flex flex-col gap-2">
          Thanh toán theo định kỳ:
          <div className="flex gap-1">
            <span className="font-[500]">{formatCurrency(price)}</span>
            <span>/</span>
            <span>{pricePeriod}</span>
          </div>
        </div>
      </div>
    );
  };

  const { mutate: mutateCreateUpdate, isLoading: isLoadingMutate } =
    useMutation(
      (params: any) =>
        id
          ? CloudVPSAPI.update({ id, params })
          : CloudVPSAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            navigate(SERVICE_CLOUD_VPS_URL.list.path);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingMutate) return;

    const data = {
      type: SERVICE_TYPE,
      planId: values.product.planId,
      locationId: values.locationId,
      osId: values.osId,
      period: values.period,
      domain: values.domain,
      isAutoRenew: values.isAutoRenew,
      userName: values.userName,
    };

    mutateCreateUpdate(data);
  };

  return (
    <div className="px-2 mt-auto sticky -bottom-0 z-[1]">
      <div className="p-4 bg-white rounded-[6px] flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <span className="text-[12px] text-gray-500">Chu kỳ thanh toán</span>
          <FormTeraItem
            name="period"
            className="mb-0"
            childrenClassName="flex flex-col gap-2"
          >
            <Select options={PeriodOptions} className="w-[300px]" />
            <div className="flex items-center gap-2">
              <span>Thuế VAT có áp dụng cho đơn hàng này</span>
              <IconText
                prefix={<ZingIcon.IconInformation />}
                className="text-blue-500 gap-1 cursor-pointer"
                onClick={() => setIsOpenModalSummary(true)}
              >
                Chi tiết
              </IconText>
            </div>
          </FormTeraItem>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="flex flex-col gap-2">
            <span className="text-[12px] text-gray-500">
              Thanh toán hôm nay
            </span>
            <IconText
              suffix={
                <Tooltip title={<PaymentTodayTooltip />} placement="top">
                  <ZingIcon.IconQuestionMark />
                </Tooltip>
              }
              className="text-blue-500 gap-1"
            >
              <span className="text-[20px] text-green-500">
                {formatCurrency(price)}
              </span>
            </IconText>
          </div>
        </div>
        <Button
          htmlType="button"
          className="flex-1 bg-blue-500"
          onClick={() => form.handleSubmit(handleSubmitForm)()}
        >
          Lưu
        </Button>
      </div>
      {isOpenModalSummary && (
        <ModalSummary
          open={isOpenModalSummary}
          close={() => setIsOpenModalSummary(false)}
          form={form}
        />
      )}
    </div>
  );
};

export default FormSummary;
