export enum INVOICE_STATUS {
  PENDING = 'pending',
  APPROVED = 'approved',
  ERROR = 'error',
  fail = 'fail',
}
export enum TRANSACTION_TYPE {
  PAY = 'pay',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}
export const RECEIPT_TYPE_TEXT = {
  other: 'Thu khác',
  customer: 'Thu tiền khách hàng',
  funds: 'Nộp quỹ',
};
export const PAYMENT_METHOD_TEXT = {
  transfer: 'Chuyển khoản',
  cash: 'Tiền mặt',
  wallet: 'Ví SUMI',
  momo: 'Ví điện tử',
};
