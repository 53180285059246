import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatDate,
  notification,
} from 'tera-dls';
import CountryAPI from '../apis';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../contants';
import ModalCreateUpdate from '../modals/ModalCreateUpdate';
import ModalDetail from '../modals/ModalDetail';
import { IOpenModalState } from '_common/interface';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [isOpenModalDetail, setIsOpenModalDetail] = useState<IOpenModalState>({
    isOpen: false,
    id: null,
  });
  const [isOpenModalCreateUpdate, setIsOpenModalCreateUpdate] =
    useState<IOpenModalState>({
      isOpen: false,
      id: null,
    });
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } =
    useMutation(
      (params: any) =>
        params.active
          ? CountryAPI.deactivate({ id: params.id })
          : CountryAPI.active({ id: params.id }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-service-package-country-list']);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleUpdateStatus = (record) =>
    confirm.warning({
      title: `Xác nhận ${
        record.active ? 'hủy kích hoạt' : 'kích hoạt'
      } quốc gia`,
      content: (
        <div className="flex flex-col">
          <p>
            {`Bạn có chắc muốn ${
              record.active ? 'hủy' : ''
            } kích hoạt quốc gia`}
          </p>
          <p>
            <b>{record?.countryName}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateUpdateStatus({ id: record.id, active: record.active }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => CountryAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-service-package-country-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = (record) =>
    confirm.warning({
      title: 'Xác nhận xóa quốc gia',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa quốc gia</p>
          <p>
            <b>{record?.countryName}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const itemActions = (record): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => setIsOpenModalDetail({ isOpen: true, id: record.id }),
      },
      {
        key: '2',
        label: 'Sửa',
        onClick: () =>
          setIsOpenModalCreateUpdate({ isOpen: true, id: record.id }),
      },
      {
        key: '3',
        label: record.active ? 'Hủy kích hoạt' : 'Kích hoạt',
        onClick: () => handleUpdateStatus(record),
      },
      {
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Cờ quốc gia',
      dataIndex: 'avatar_url',
      render: (avatar_url) => <img src={avatar_url} alt="Country Flag" />,
    },
    {
      title: 'Mã quốc gia',
      dataIndex: 'countryCode',
      render: (countryCode) => (
        <span className="text-blue-500">{countryCode}</span>
      ),
    },
    {
      title: 'Tên quốc gia',
      dataIndex: 'countryName',
    },
    {
      title: 'Khu vực',
      dataIndex: 'areaCode',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      render: (active) => (
        <Tag color={TABLE_STATUS_COLOR[active]} className="font-[500]">
          {TABLE_STATUS_TEXT[active]}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render: (created_at) => formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updated_at',
      render: (updated_at) => formatDate(updated_at, DATE_TIME_FORMAT),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading || isLoadingUpdateStatus || isLoadingDelete}
        className="[&_.tera-table-cell]:align-middle"
        {...props}
      />
      {isOpenModalCreateUpdate && (
        <ModalCreateUpdate
          open={isOpenModalCreateUpdate.isOpen}
          close={() => setIsOpenModalCreateUpdate({ isOpen: false })}
          id={isOpenModalCreateUpdate.id}
        />
      )}
      {isOpenModalDetail && (
        <ModalDetail
          open={isOpenModalDetail.isOpen}
          close={() => setIsOpenModalDetail({ isOpen: false })}
          id={isOpenModalDetail.id}
        />
      )}
    </Fragment>
  );
};

export default Table;
