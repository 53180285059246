const WindowIcon = (props) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 1.5H2.5C2.23478 1.5 1.98043 1.60536 1.79289 1.79289C1.60536 1.98043 1.5 2.23478 1.5 2.5V16.5C1.5 16.7652 1.60536 17.0196 1.79289 17.2071C1.98043 17.3946 2.23478 17.5 2.5 17.5H16.5C16.7652 17.5 17.0196 17.3946 17.2071 17.2071C17.3946 17.0196 17.5 16.7652 17.5 16.5V2.5C17.5 2.23478 17.3946 1.98043 17.2071 1.79289C17.0196 1.60536 16.7652 1.5 16.5 1.5H9.5Z"
      stroke="#3F83F8"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path d="M9.5 1.5V17" stroke="#3F83F8" stroke-width="2" />
    <path d="M17.25 9.25L1.75 9.25" stroke="#3F83F8" stroke-width="2" />
  </svg>
);
export default WindowIcon;
