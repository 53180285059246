import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { timeFormat } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import { PAYMENT_METHOD_TEXT } from 'pages/InvoiceManagement/Invoice/constants';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
} from 'tera-dls';
import {
  RenderTransactionType,
  statusTransactionRender,
} from '../../../constants';

type TableTransactionHistoryProps = ITeraTableProps & {
  renderDropdown?: (record: any) => DropdownItem[];
};
const TableTransactionHistory = ({
  renderDropdown,

  ...restProps
}: TableTransactionHistoryProps) => {
  const columns: ColumnsType<any> = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
      render: (data) => (
        <span className="text-blue-600 line-clamp-1">{data}</span>
      ),
      width: 150,
    },
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice_code',
      width: 150,
      render: (val) => <span>{val}</span>,
    },
    {
      key: 'username',
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      render: (data) => <span className="line-clamp-1">{data}</span>,
      width: 150,
    },
    {
      key: 'full_name',
      title: 'Họ và tên',
      dataIndex: 'full_name',
      render: (data) => <span className="line-clamp-1">{data}</span>,
      width: 200,
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (data) => <RenderTransactionType type={data} />,
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      render: (amount) => formatCurrency(amount),
      width: 150,
    },
    {
      title: 'Phương thức',
      dataIndex: 'methods',
      width: 150,
      render: (method) => PAYMENT_METHOD_TEXT[method],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => statusTransactionRender[status],
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      render: (data) => formatDate(data, timeFormat.date_time),
      width: 150,
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'updated_at',
      render: (data) => formatDate(data, timeFormat.date_time),
      width: 150,
    },
    {
      dataIndex: 'action',
      width: 80,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown && renderDropdown(record);
        return <ActionDropdown dropdownItems={dropdownItems} trigger="click" />;
      },
    },
  ];

  return <TableTera columns={columns} {...restProps} />;
};

export default TableTransactionHistory;
