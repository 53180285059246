import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowsRightLeftSolid,
  Badge,
  Button,
  PaginationProps,
  PlusOutlined,
  TabItemType,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import DomainAPI from './apis';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { TABS_STATUS_ITEM, TABS_STATUS_TEXT } from './constants';
import ModalTransferMultipleDomain from './modals/ModalTransferMultipleDomain';
import { SERVICE_DOMAIN_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenModalTransfer, setIsOpenModalTransfer] =
    useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${SERVICE_DOMAIN_URL.list.path}${paramString}`);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateFilter({ status: key, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TABS_STATUS_ITEM.map((item) => {
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TABS_STATUS_TEXT[item]} <Badge count={10} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, []);

  const handleSearch = (value) => {
    handleUpdateFilter({ keyword: value?.keyword, page: 1 });
  };

  const handleFilter = (values) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const {
    data: domainList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-service-domain-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return DomainAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateFilter({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(domainList?.limit || 10);

    handleUpdateFilter({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách tên miền"
        actionRight={
          <div className="flex gap-2">
            <Button
              prefix={<ArrowsRightLeftSolid />}
              className="bg-green-500"
              onClick={() => setIsOpenModalTransfer(true)}
            >
              Transfer nhiều tên miền
            </Button>
            <Button
              prefix={<PlusOutlined />}
              className="bg-blue-500"
              onClick={() => navigate(`${SERVICE_DOMAIN_URL.create.path}`)}
            >
              Thêm mới
            </Button>
          </div>
        }
        onClickFilter={() => setIsOpenFilter(true)}
        middleContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
            className="mb-0"
          />
        }
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: ['date', 'vehicle_from', 'vehicle_to'],
        }}
      >
        <Table
          rowKey={'id'}
          data={domainList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: domainList?.total || 0,
            current: domainList?.current_page,
            pageSize: domainList?.per_page || 10,
            to: domainList?.to,
            from: domainList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenModalTransfer && (
        <ModalTransferMultipleDomain
          open={isOpenModalTransfer}
          close={() => setIsOpenModalTransfer(false)}
        />
      )}
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default ListPage;
