export const SERVICE_TYPE = 'cloud_vps';
export enum CLOUD_VPS_STATUS {
  INITIALIZING = 'initializing',
  RUNNING = 'running',
  BLOCK = 'block',
}
export const TABS_STATUS_ITEM = ['all', 'running', 'initializing', 'block'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  initializing: 'Khởi tạo',
  running: 'Đang chạy',
  block: 'Dịch vụ đã hủy',
};
export const TABLE_STATUS_COLOR = {
  initializing: 'gray02',
  running: 'green03',
  block: 'red03',
};
export const TABLE_STATUS_TEXT = {
  initializing: 'Khởi tạo',
  running: 'Đang chạy',
  block: 'Dịch vụ đã hùy',
};
export const PRICE_PERIOD_TEXT = {
  daily: '1 ngày',
  threeDays: '3 ngày',
  weekly: '1 tuần',
  monthly: '1 tháng',
  twoMonths: '2 tháng',
  threeMonths: '3 tháng',
  sixMonths: '6 tháng',
  oneYear: '1 năm',
  twoYears: '2 năm',
  threeYears: '3 năm',
  fourYears: '4 năm',
  fiveYears: '5 năm',
};
export const deleteOptions = [
  { value: 1, label: 'Đổi IP và giữ lại dữ liệu' },
  { value: 2, label: 'Đổi IP và xóa dữ liệu' },
];
