import useConfirm from '_common/hooks/useConfirm';
import InvoiceContent from 'pages/InvoiceManagement/Invoice/pages/Detail/components/InvoiceContent';
import { USER_INFORMATION_URL } from 'pages/UserManagement/UserInformation/url';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, Button } from 'tera-dls';

const InvoiceTemplate = () => {
  const navigate = useNavigate();
  const { detailId } = useParams();
  const confirm = useConfirm();

  const handlePayment = (id: number, name: string) => {
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <span className="font-bold">{name}</span> này không?
          </p>
        </div>
      ),
      onOk: () => {
        id;
      },
    });
  };

  return (
    <div className="tera-page-form !gap-0 text-sm">
      <div className="page-header-sticky bg-[#F3F3F9]">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="size-6" />
            </div>
            <Breadcrumb
              separator={'/'}
              items={[
                {
                  title: (
                    <a
                      className="cursor-pointer text-blue-400 italic tracking-[0.2px] text-sm"
                      onClick={() => navigate(USER_INFORMATION_URL.list.path)}
                    >
                      Danh sách người dùng
                    </a>
                  ),
                },
                {
                  title: (
                    <a
                      className="cursor-pointer text-blue-400 italic tracking-[0.2px] text-sm"
                      onClick={() =>
                        navigate(
                          `${USER_INFORMATION_URL.detail.path}/${detailId}`,
                        )
                      }
                    >
                      Cấu hình
                    </a>
                  ),
                },
                {
                  title: (
                    <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px]">
                      Tên gian hàng
                    </a>
                  ),
                },
              ]}
            />
          </div>
          <div className="page-header-v2__function">
            <Button onClick={() => handlePayment(1, '#HD123')}>
              Xác nhận thanh toán
            </Button>
          </div>
        </div>
      </div>
      <div className="page-content-v2 rounded-md !gap-[16px] px-2.5 h-full mx-2.5 pb-5 text-sm">
        <InvoiceContent dataDetail={{}} />
      </div>
    </div>
  );
};

export default InvoiceTemplate;
