import TeraFilter from '_common/component/Filter';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  MAXIMUM_CURRENCY,
} from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PAYMENT_METHOD_TEXT, RECEIPT_TYPE_TEXT } from '../constants';
import RangeNumber from '_common/dof/Control/RangeNumber';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: IProps) {
  const form = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (initialValue) {
      form.reset({
        ...initialValue,
        ...(initialValue.date && {
          date: moment(initialValue.date),
        }),
        ...(initialValue.accounting_date && {
          accounting_date: moment(initialValue.accounting_date),
        }),
      });
    }
  }, [initialValue]);

  const handleSubmitForm = (values: any) => {
    onFilter({
      ...values,
      date: values.date ? moment(values.date).format(DATE_BACKEND_FORMAT) : '',
      accounting_date: values.accounting_date
        ? moment(values.accounting_date).format(DATE_BACKEND_FORMAT)
        : '',
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      transaction_type: '',
      methods: '',
      accounting_date: '',
      date: '',
      amount_from: '',
      amount_to: '',
    };
    onFilter(values);
    onClose();
  };

  const typeOptions = Object.entries(RECEIPT_TYPE_TEXT).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const paymentMethod = Object.entries(PAYMENT_METHOD_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Loại phiếu thu" name="transaction_type">
          <Select options={typeOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select options={paymentMethod} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngày phiếu thu" name="date">
          <DatePicker format={DATE_FORMAT} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngày hạch toán" name="accounting_date">
          <DatePicker format={DATE_FORMAT} allowClear />
        </FormTeraItem>
        <p className="mb-2.5">Tổng giá trị</p>
        <RangeNumber
          startFormName="amount_from"
          endFormName="amount_to"
          endInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
          startInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
        />
        <span className="text-red-500 cursor-pointer" onClick={handleReset}>
          Hủy bộ lọc
        </span>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
