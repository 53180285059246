import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatDate,
  notification,
} from 'tera-dls';
import DomainAPI from '../apis';
import {
  DOMAIN_AUTORENEW_COLOR,
  DOMAIN_AUTORENEW_TEXT,
  DOMAIN_PERIOD_TEXT,
  DOMAIN_STATUS,
  DOMAIN_STATUS_COLOR,
  DOMAIN_STATUS_TEXT,
} from '../constants';
import { SERVICE_DOMAIN_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } =
    useMutation(
      (params: any) =>
        _.isEqual(params.type, DOMAIN_STATUS.ACTIVE)
          ? DomainAPI.deactivate({ id: params.id })
          : DomainAPI.active({ id: params.id }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-service-domain-list']);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleUpdateStatus = (record: any) =>
    confirm.warning({
      title: `Xác nhận ${
        _.isEqual(record.status, DOMAIN_STATUS.ACTIVE) ? 'hủy' : ''
      } kích hoạt tên miền`,
      content: (
        <div className="flex flex-col">
          <p>
            {`Bạn có chắc muốn ${
              _.isEqual(record.status, DOMAIN_STATUS.ACTIVE) ? 'hủy' : ''
            } kích hoạt tên miền`}
          </p>
          <p>
            <b>{record.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateUpdateStatus({ id: record.id, type: record.status }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => DomainAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-service-domain-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) =>
    confirm.warning({
      title: 'Xác nhận xóa tên miền',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa tên miền</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () =>
          navigate(`${SERVICE_DOMAIN_URL.detail.path}/${record.id}`),
      },
      {
        key: '2',
        label: 'Sửa',
        onClick: () =>
          navigate(`${SERVICE_DOMAIN_URL.update.path}/${record.id}`),
      },
      {
        key: '3',
        label: _.isEqual(record.status, DOMAIN_STATUS.ACTIVE)
          ? 'Hủy kích hoạt'
          : 'Kích hoạt',
        onClick: () => handleUpdateStatus(record),
      },
      {
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'IP',
      dataIndex: 'ip',
      width: 150,
      render: () => <span className="text-blue-500">192.168.1.1</span>,
    },
    {
      title: 'Tên miền',
      dataIndex: 'name',
      width: 150,
      render: (name) => <span className="text-blue-500">{name}</span>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag color={DOMAIN_STATUS_COLOR[status]} className="font-[500]">
          {DOMAIN_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Chu kỳ thanh toán',
      dataIndex: 'period',
      width: 150,
      render: (period) => DOMAIN_PERIOD_TEXT[period],
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'date_created',
      width: 150,
      render: (date_created) => formatDate(date_created, DATE_TIME_FORMAT),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expires',
      width: 150,
      render: (expires) => formatDate(expires, DATE_TIME_FORMAT),
    },
    {
      title: 'Tự động gia hạn',
      dataIndex: 'autorenew',
      width: 150,
      render: (autorenew) => (
        <Tag
          color={DOMAIN_AUTORENEW_COLOR[autorenew]}
          className={`font-[500] ${autorenew !== 1 ? '!text-yellow-600' : ''}`}
        >
          {DOMAIN_AUTORENEW_TEXT[autorenew]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading || isLoadingUpdateStatus || isLoadingDelete}
      className="[&_.tera-table-cell]:align-middle"
      {...props}
    />
  );
};

export default Table;
