import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import Content from './containers/Content';

const Statistic = () => {
  // const affectParams = {
  //   type: params?.type,
  //   transaction_type: params?.transaction_type,
  //   quantity_show: params?.quantity_show,
  //   ...(params?.status_order && { status_order: params?.status_order }),
  //   to_date: params?.to_date.format(DATE_BACKEND_FORMAT),
  //   from_date: params?.from_date.format(DATE_BACKEND_FORMAT),
  // };

  // const { data: response, isFetching } = useQuery(
  //   ['get-transaction_type', affectParams, business?.id],
  //   () => {
  //     return DashboardApi.dashboard({
  //       params: { ...(affectParams ?? {}), type_chart: 'transaction_type' },
  //     });
  //   },
  //   {
  //     cacheTime: 300000,
  //     staleTime: 300000,
  //   },
  // );

  const data = useMemo(() => {
    // if (
    //   !response?.top_type_transaction ||
    //   response?.top_type_transaction?.length === 0
    // )
    //   return {
    //     labels: [],
    //     datasets: [
    //       {
    //         data: [1],
    //         fill: false,
    //         borderColor: '#F9C74F',
    //         backgroundColor: '#F9C74F',
    //       },
    //     ],
    //   };
    const labels = [
      'Tên miền',
      'Web Hosting',
      'Email & Bảo mật',
      'Cloud Server',
      'SSL',
      'Cloud VPS',
    ];
    const value = [5000, 2800, 3700, 3900, 2800, 3800];

    return {
      labels,
      datasets: [
        {
          data: value,
          fill: false,
          borderColor: '#F98080',
          backgroundColor: '#F98080',
        },
      ],
    };
  }, []);

  return (
    <Content
      chartClassName="!h-[400px]"
      params={{}}
      value={data}
      loading={false}
    />
  );
};

export default observer(Statistic);
