import FormTera from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, Button, Spin } from 'tera-dls';
import { USER_INFORMATION_URL } from '../../url';
import UserFormData from './UserFormData';

const UserInformationForm = () => {
  const form = useForm({ mode: 'onChange' });
  const navigate = useNavigate();

  const handleSubmitForm = (values) => {
    values;
  };

  useEffect(() => {
    form.setValue('is_active', true);
  }, []);

  return (
    <Spin spinning={false}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="size-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                items={[
                  {
                    title: (
                      <a className="cursor-pointer text-blue-400 italic text-sm">
                        Danh sách người dùng
                      </a>
                    ),
                    onClick: () => navigate(USER_INFORMATION_URL.list.path),
                  },
                  {
                    title: (
                      <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px] text-sm">
                        Thêm người dùng
                      </a>
                    ),
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function shrink-0">
              <Button onClick={form.handleSubmit(handleSubmitForm)}>Lưu</Button>
            </div>
          </div>
        </div>
        <div className="page-content-v2 bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 pb-5 !gap-y-0">
          <FormTera form={form} onSubmit={handleSubmitForm}>
            <UserFormData />
          </FormTera>
        </div>
      </div>
    </Spin>
  );
};

export default UserInformationForm;
