import HeaderViewList from '_common/component/HeaderViewList';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  PlusOutlined,
  TabItemType,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { TABS_STATUS_ITEM, TABS_STATUS_TEXT, mockDataList } from './constants';
import { SERVICE_SSL_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${SERVICE_SSL_URL.list.path}${paramString}`);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateFilter({ status: key, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TABS_STATUS_ITEM.map((item) => {
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TABS_STATUS_TEXT[item]} <Badge count={10} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, []);

  const handleSearch = (value) => {
    handleUpdateFilter({ keyword: value?.keyword, page: 1 });
  };

  const handleFilter = (values) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách SSL"
        actionRight={
          <Button
            prefix={<PlusOutlined />}
            className="bg-blue-500"
            onClick={() => navigate(SERVICE_SSL_URL.create.path)}
          >
            Thêm mới
          </Button>
        }
        onClickFilter={() => setIsOpenFilter(true)}
        middleContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
            className="mb-0"
          />
        }
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: ['date', 'vehicle_from', 'vehicle_to'],
        }}
      >
        <Table rowKey={'id'} data={mockDataList} queryParams={queryParams} />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default ListPage;
