import { useState } from 'react';
import { Tabs } from 'tera-dls';
import Secure from './Secure';
import SSHKeys from './SSHKeys';
import Verify from './Verify';

const Security = () => {
  const [params, setParams] = useState<any>();

  const tabItems = [
    {
      key: 'security',
      label: 'Bảo mật',
    },
    {
      key: 'ssh',
      label: 'SSH Keys',
    },
    {
      key: 'verify',
      label: 'Xác thực 2 bước',
    },
  ];

  const tabData = {
    security: <Secure />,
    ssh: <SSHKeys />,
    verify: <Verify />,
  };

  return (
    <div className="flex flex-col gap-[16px] pb-2.5 text-sm">
      <Tabs
        onChange={setParams}
        items={tabItems}
        activeKey={params || 'security'}
        className="mb-0 pt-0 bg-white rounded-t-md"
      />
      <div className="pt-[16px]">{tabData[params] ?? <Secure />}</div>
    </div>
  );
};

export default Security;
