const CloseIcon = (props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 16.5C12.9183 16.5 16.5 12.9183 16.5 8.5C16.5 4.08172 12.9183 0.5 8.5 0.5C4.08172 0.5 0.5 4.08172 0.5 8.5C0.5 12.9183 4.08172 16.5 8.5 16.5ZM6.78033 5.71967C6.48744 5.42678 6.01256 5.42678 5.71967 5.71967C5.42678 6.01256 5.42678 6.48744 5.71967 6.78033L7.43934 8.5L5.71967 10.2197C5.42678 10.5126 5.42678 10.9874 5.71967 11.2803C6.01256 11.5732 6.48744 11.5732 6.78033 11.2803L8.5 9.56066L10.2197 11.2803C10.5126 11.5732 10.9874 11.5732 11.2803 11.2803C11.5732 10.9874 11.5732 10.5126 11.2803 10.2197L9.56066 8.5L11.2803 6.78033C11.5732 6.48744 11.5732 6.01256 11.2803 5.71967C10.9874 5.42678 10.5126 5.42678 10.2197 5.71967L8.5 7.43934L6.78033 5.71967Z"
      fill="#6B7280"
    />
  </svg>
);
export default CloseIcon;
