import TeraSearch from '_common/dof/Control/Search';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';
import _ from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MagnifyingGlassOutlined, getQueryParams } from 'tera-dls';

interface SearchProps {
  onSearch: (value) => void;
}

function Search({ onSearch }: SearchProps) {
  const [formRef] = useFormTera();

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleSearch = (value) => {
    if (formRef?.current?.isDirty) {
      onSearch(value);
      formRef?.current?.reset({ ...value }, { keepValues: true });
    }
  };

  useEffect(() => {
    if (queryParams) {
      const values = _.pick(queryParams, ['keyword']);

      formRef?.current?.reset({
        keyword: values?.keyword,
      });
    }
  }, [queryParams]);

  return (
    <FormTera ref={formRef} onSubmit={handleSearch} isLoading={false}>
      <FormTeraItem className="!mb-0" name="keyword">
        <TeraSearch
          placeholder="Tìm kiếm theo tên vị trí"
          icon={<MagnifyingGlassOutlined />}
          className="!py-[6px]"
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default Search;
