import { useMutation } from '@tanstack/react-query';
import NumberToWord from '_common/component/NumberToWord';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import useConfirm from '_common/hooks/useConfirm';
import InvoiceAPI from 'pages/InvoiceManagement/Invoice/apis';
import {
  PAYMENT_METHOD_TEXT,
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
  TRANSACTION_TEXT,
} from 'pages/InvoiceManagement/Invoice/constants';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { useNavigate } from 'react-router-dom';
import zingServer from 'styles/images/zing-server.png';
import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  Col,
  formatCurrency,
  formatDate,
  notification,
  PrinterOutlined,
  Row,
  Select,
  Spin,
  Tag,
  XMarkOutlined,
} from 'tera-dls';

interface IInvoiceContentProps {
  dataDetail: any;
}

const cellClassName = 'border border-gray-700 px-2 py-2';

const InvoiceContent = ({ dataDetail }: IInvoiceContentProps) => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  console.log(dataDetail);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (params: any) => InvoiceAPI.delete({ id: params?.id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(INVOICE_URL.list.path);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa hóa đơn',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa hóa đơn</p>
          <p>
            <span className="font-bold">{dataDetail.invoice_code}</span> này
            không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateDelete({
          id: dataDetail.id,
        }),
    });
  };

  return (
    <Spin spinning={isLoadingDelete}>
      <Row className="flex justify-between">
        <div className="flex flex-col gap-2.5">
          <p className="text-xl font-semibold">
            Hoá đơn {dataDetail?.invoice_code}
          </p>
          <div className="flex gap-5 items-center font-normal">
            <Tag
              className="font-medium"
              color={TABLE_STATUS_COLOR[dataDetail?.status]}
            >
              {TABLE_STATUS_TEXT[dataDetail?.status]}
            </Tag>
            <span className="text-gray-600">
              {formatDate(dataDetail?.created_at, DATE_TIME_FORMAT)}
            </span>
          </div>
        </div>
        <div className="flex gap-[16px] items-center">
          <Button className="flex gap-[8px] font-light bg-gray-500">
            <PrinterOutlined className="size-4" />
            <span>In hóa đơn</span>
          </Button>
          <Button className="flex gap-[8px] font-light bg-gray-500">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="size-4"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2.03726C0 1.60883 0.170192 1.19795 0.473135 0.89501C0.776079 0.592067 1.18696 0.421875 1.61538 0.421875L10.4537 0.421875L14 3.96818V14.9603C14 15.3888 13.8298 15.7996 13.5269 16.1026C13.2239 16.4055 12.813 16.5757 12.3846 16.5757H1.61538C1.18696 16.5757 0.776079 16.4055 0.473135 16.1026C0.170192 15.7996 0 15.3888 0 14.9603V2.03726ZM2.69231 6.88341H1.07692V12.268H2.15385V10.1142H2.69231C3.12073 10.1142 3.53161 9.94399 3.83456 9.64105C4.1375 9.33811 4.30769 8.92723 4.30769 8.4988C4.30769 8.07037 4.1375 7.65949 3.83456 7.35655C3.53161 7.05361 3.12073 6.88341 2.69231 6.88341ZM7 6.88341H5.38462V12.268H7C7.42843 12.268 7.83931 12.0978 8.14225 11.7949C8.44519 11.492 8.61539 11.0811 8.61539 10.6526V8.4988C8.61539 8.07037 8.44519 7.65949 8.14225 7.35655C7.83931 7.05361 7.42843 6.88341 7 6.88341ZM9.69231 12.268V6.88341H12.9231V7.96034H10.7692V9.03726H11.8462V10.1142H10.7692V12.268H9.69231Z"
                fill="white"
              />
            </svg>
            <span>Tải PDF</span>
          </Button>
          <Button
            className="flex gap-[8px] font-light bg-gray-500"
            onClick={handleDelete}
          >
            <XMarkOutlined className="size-5" />
            <span>Xóa hóa đơn</span>
          </Button>
        </div>
      </Row>
      <Row className="grid-cols-4 gap-[16px]">
        <Col className="bg-white shadow-xsm rounded-md p-[16px] col-span-1 flex flex-col gap-[30px] h-fit">
          <div className="flex gap-2.5 flex-col items-center">
            <p className="text-[#111827]">Thanh toán ngay</p>
            <Select
              value={1}
              placeholder="Vui lòng chọn"
              options={[
                {
                  value: 1,
                  label: 'ACB - Ngân hàng TMCP Á Châu',
                },
                {
                  value: 2,
                  label: 'VCB - Ngân hàng TMCP Ngoại Thương Việt Nam',
                },
                {
                  value: 3,
                  label: 'Ví điện tử MoMo',
                },
                {
                  value: 4,
                  label: 'Thanh toán QRPAY/Thẻ ATM/Credit Card',
                },
                {
                  value: 5,
                  label: 'Ví của tôi',
                },
              ]}
            />
          </div>
          <div className="w-468">
            <img src="https://s3-alpha-sig.figma.com/img/fc7d/cd16/7657fd74e2c6de0342f8d61074fe305c?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Cuk8EM~v~mCD14PiTw7DjaRZYSoC1cGvaGHVVwL6kvNQRfHGpXvf8cwUa7zVIn~zXPxggblsDV6Oe7SFlZmwqvN0RWy0arc6eEcliDM8HKj-Afge1jYZbDUjnIb2f7GAPeR8xgdNc8oiXdcF-bVrgJ1AvUD7yTRzhQ6LTbujedETST35Q1Wi~B04tAbeX4HXc0gTQqvI6NvhZQeM2JllghfBrJ3AC5HBFaW2L0o7gb3UmTIxXyu-RHqf0Wc68X5XbR9agQuOzUI31YBD-lh8CporOewiN5oTShesDQqoYNe0kZkGDh3XjA8oFYpAb9QkbqzBoN8Wces48UOqJSZFZQ__" />
          </div>
          <div className="flex flex-col gap-2.5 text-[#374151]">
            <p>Ngân hàng Á Châu ACB</p>
            <p>
              <span className="text-gray-400">Số tài khoản:</span> 6717021
            </p>
            <p>
              <span className="text-gray-400">Chủ tài khoản:</span> Tran Thanh
              Binh
            </p>
            <p>
              <span className="text-gray-400">Nội dung:</span>{' '}
              {dataDetail?.invoice_code}
            </p>
            <p>
              <span className="text-gray-400">Số tiền: </span>
              {formatCurrency(dataDetail?.amount)}
            </p>
          </div>
          <p className="text-gray-500 italic">
            Quý khách vui lòng điền đúng số hoá đơn và số tiền hoặc quét mã QR
            để thanh toán được xác nhận nhanh chóng.
          </p>
        </Col>
        <Col className="bg-white shadow-xsm  rounded-md p-[16px] col-span-3 py-[40px] px-10">
          <div className="flex flex-col gap-[24px]">
            <header className="flex  items-center justify-start divide-x-[1px]">
              <img src={zingServer} className="h-[70px] rounded  pr-[30px]" />
              <div className="flex pl-[30px] flex-col gap-[6px] ">
                <h2 className="text-gray-700 font-semibold">
                  CÔNG TY CỔ PHẦN TẬP ĐOÀN ZINGSERVER
                </h2>
                <p>
                  <span className="text-gray-500">Mã số thuế: </span>
                  <span className="font-medium"> 0315679836</span>
                </p>
                <p>
                  <span className="text-gray-500">Địa chỉ: </span>
                  N01T3, KĐT Ngoại Giao Đoàn, Xuân Tảo, Bắc TL, HN.
                </p>
                <p>
                  <span className="text-gray-500">Điện thoại: </span>
                  0909 123 456
                </p>
                <p>
                  <span className="text-gray-500">Số tài khoản: </span>
                  45637453 Tại Ngân hàng TMCP Ngoại Thương Việt Nam
                </p>
              </div>
            </header>
            <h1 className="text-2xl font-bold text-center">
              HÓA ĐƠN {dataDetail?.invoice_code}
            </h1>
            <main className="flex flex-col w-full">
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-[8px] col-span-1 p-[10px]">
                  <p>
                    <span className="font-bold">Họ tên người mua hàng:</span>{' '}
                    {dataDetail?.account_name}
                  </p>
                  <p>
                    <span className="font-bold">Địa chỉ: </span> 12 Phan Bội
                    Châu, phường Bến Nghé, quận 1
                  </p>
                  <p>
                    <span className="font-bold">Hình thức thanh toán:</span>{' '}
                    {PAYMENT_METHOD_TEXT[dataDetail?.methods]}
                  </p>
                </div>
                <div className="flex flex-col gap-[8px] col-span-1 p-[10px]">
                  <p>
                    <span className="font-bold">Hóa đơn ngày:</span>{' '}
                    {formatDate(dataDetail?.created_at, DATE_TIME_FORMAT)}
                  </p>
                  <p>
                    <span className="font-bold">Ngày đến hạn:</span>
                  </p>
                </div>
              </div>
              <div className="flex-1">
                <table className="w-full border-collapse border border-gray-600">
                  <thead>
                    <tr>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        STT
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Tên hàng hóa, dịch vụ
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Đơn vị tính
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Số lượng
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Đơn giá
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Thành tiền
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Thuế suất
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Tiền Thuế GTGT
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        1
                      </td>
                      <td className={customTwMerge(cellClassName)}>
                        {TRANSACTION_TEXT[dataDetail?.transaction_type]}
                      </td>
                      <td
                        className={customTwMerge('text-center', cellClassName)}
                      ></td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        1
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        1
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        {formatCurrency(dataDetail?.amount)}
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        {formatCurrency(0)}
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        {formatCurrency(0)}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={5}
                        className={customTwMerge(
                          'text-right font-bold',
                          cellClassName,
                        )}
                      >
                        Tạm tính
                      </td>
                      <td
                        colSpan={3}
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        {formatCurrency(dataDetail?.amount)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={5}
                        className={customTwMerge(
                          'text-right font-bold',
                          cellClassName,
                        )}
                      >
                        Tín dụng
                      </td>
                      <td
                        colSpan={3}
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={5}
                        className={customTwMerge(
                          'text-right font-bold',
                          cellClassName,
                        )}
                      >
                        Thuế VAT
                      </td>
                      <td
                        colSpan={3}
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={5}
                        className={customTwMerge(
                          'text-right font-bold',
                          cellClassName,
                        )}
                      >
                        Tổng
                      </td>
                      <td
                        colSpan={3}
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        {formatCurrency(dataDetail?.amount)}
                      </td>
                    </tr>
                  </tfoot>
                </table>

                <table className="w-full border-collapse border border-t-0 border-gray-600">
                  <thead>
                    <tr>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Tổng hợp
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Thành tiền trước thuế
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Thuế GTGT (VAT)
                      </th>
                      <th
                        className={customTwMerge('text-center', cellClassName)}
                      >
                        Tổng tiền thanh toán
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className={customTwMerge('font-medium', cellClassName)}
                      >
                        Tổng không chịu thuế
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        {formatCurrency(dataDetail?.amount)}
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={customTwMerge('font-medium', cellClassName)}
                      >
                        Tổng chịu thuế 0%
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={customTwMerge('font-medium', cellClassName)}
                      >
                        Tổng chịu thuế 10%
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                      <td
                        className={customTwMerge('text-right', cellClassName)}
                      >
                        0 đ
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  className={customTwMerge(
                    'font-bold',
                    cellClassName,
                    'border-t-0',
                  )}
                >
                  Số tiền viết bằng chữ:{' '}
                  <NumberToWord
                    number={dataDetail?.amount}
                    isUpperCaseFirstLetter
                  />
                </div>
              </div>
            </main>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default InvoiceContent;
