import BasicLayout from '_common/component/Layout/BasicLayout';
import PageLayout from '_common/component/Layout/PageLayout';
import UnAuthLayout from '_common/component/Layout/UnAuthLayout';
import PageNotPermission from '_common/component/PageNotPermission';
import PageNotfound from '_common/component/PageNotfound';
import PageUnauthorized from '_common/component/PageUnauthorized';
import { IRouteProps } from '_common/interface/router';
import CheckAuthPage from 'pages/Auth/CheckAuth';
import Login from 'pages/Auth/Login';
import LogoutPage from 'pages/Auth/Logout';
import { ConfigAffiliatesRouter } from 'pages/ConfigAffiliates/router';
import { ConfigPaymentMethodRouter } from 'pages/ConfigPaymentMethod/router';
import { InvoiceRouter } from 'pages/InvoiceManagement/Invoice/router';
import { InvoicePaymentRouter } from 'pages/InvoiceManagement/Payment/router';
import { InvoiceReceiptRouter } from 'pages/InvoiceManagement/Receipt/router';
import { StatisticRouter } from 'pages/InvoiceManagement/Statistic/router';
import { NotificationDetailRouter } from 'pages/NotificationDetail/router';
import { OverviewRouter } from 'pages/Overview/router';
import { ServiceCloudServerRouter } from 'pages/ServiceManagement/CloudServer/router';
import { ServiceCloudVPSRouter } from 'pages/ServiceManagement/CloudVPS/router';
import { ServiceDomainRouter } from 'pages/ServiceManagement/Domain/router';
import { ServiceEmailSecurityRouter } from 'pages/ServiceManagement/EmailSecurity/router';
import { ServiceHostingRouter } from 'pages/ServiceManagement/Hosting/router';
import { ServiceSSLRouter } from 'pages/ServiceManagement/SSL/router';
import { ServicePackageCountryRouter } from 'pages/ServicePackage/Country/router';
import { ServicePackageDatacenterRouter } from 'pages/ServicePackage/Datacenter/router';
import { ServiceLocationRouter } from 'pages/ServicePackage/Location/router';
import { ServicePackageOperatingSystemRouter } from 'pages/ServicePackage/OperatingSystem/router';
import { ServicePackageRouter } from 'pages/ServicePackage/Service/router';
import { MyInformationRouter } from 'pages/UserManagement/MyInformation/router';
import { UserInformationRouter } from 'pages/UserManagement/UserInformation/router';
import { Navigate, Route, Routes } from 'react-router-dom';
import CheckAuth from 'routers/CheckAuth';
import MiddlewareRouter from 'routers/MiddlewareRouter';

// dashboard router
const renderRouter = (objectRouter: IRouteProps[]) => {
  return objectRouter.map((route: IRouteProps) => {
    const { key, component, path, actions, action_type } = route;
    return (
      <Route
        key={key}
        path={path}
        element={
          <PageLayout
            page_key={key}
            actions={actions}
            action_type={action_type}
          >
            {component}
          </PageLayout>
        }
      />
    );
  });
};
renderRouter;

export const Routers = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Navigate to="overview" />} />

        {renderRouter(OverviewRouter)}
        {renderRouter(NotificationDetailRouter)}
        <Route path="user">
          {renderRouter(UserInformationRouter)}
          {renderRouter(MyInformationRouter)}
        </Route>
        <Route path="service">
          {renderRouter(ServiceDomainRouter)}
          {renderRouter(ServiceEmailSecurityRouter)}
          {renderRouter(ServiceHostingRouter)}
          {renderRouter(ServiceCloudServerRouter)}
          {renderRouter(ServiceSSLRouter)}
          {renderRouter(ServiceCloudVPSRouter)}
        </Route>
        <Route path="invoice">
          {renderRouter(InvoiceRouter)}
          {renderRouter(InvoiceReceiptRouter)}
          {renderRouter(InvoicePaymentRouter)}
          {renderRouter(StatisticRouter)}
        </Route>
        <Route path="payment">{renderRouter(ConfigPaymentMethodRouter)}</Route>
        <Route path="service-package">
          {renderRouter(ServicePackageRouter)}
          {renderRouter(ServicePackageOperatingSystemRouter)}
          {renderRouter(ServicePackageCountryRouter)}
          {renderRouter(ServiceLocationRouter)}
          {renderRouter(ServicePackageDatacenterRouter)}
        </Route>
        <Route path="affiliates">{renderRouter(ConfigAffiliatesRouter)}</Route>

        <Route path="logout" element={<LogoutPage />} />
      </Route>

      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="check-auth" element={<CheckAuthPage />} />
      </Route>

      <Route path="*" element={<PageNotfound />} />
      <Route path="/403" element={<PageNotPermission />} />
      <Route path="/401" element={<PageUnauthorized />} />
    </Routes>
  );
};
