import { messageWarning } from '_common/constants/message';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { Modal, Spin } from 'tera-dls';

interface IModalNoteProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalNote = ({ open, close }: IModalNoteProps): JSX.Element => {
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();

  const handleCloseConfirm = () => {
    confirm.warning({
      title: 'Thoát bản ghi',
      content: (
        <>
          <p>{messageWarning.WARNING_EXIT_1}</p>
          <p>{messageWarning.WARNING_EXIT_2}</p>
        </>
      ),
      onOk: () => close(),
    });
  };

  return (
    <Modal
      title="Thêm ghi chú cho Cloud VPS"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={close}
      onCancel={handleCloseConfirm}
      className="md:w-[500px]"
      bodyClassName="!pb-2"
    >
      <Spin spinning={false}>
        <FormTera form={form}>
          <FormTeraItem name="note" className="mb-0">
            <TextArea
              placeholder="Vui lòng nhập"
              className="h-[100px] resize-none"
            />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
};

export default ModalNote;
