import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { methodsText } from 'pages/ConfigPaymentMethod/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function TransactionFilter({ open, onClose, onFilter, initialValue }: IProps) {
  const form = useForm();

  useEffect(() => {
    if (initialValue) {
      form.reset({
        ...(initialValue?.methods && { methods: initialValue?.methods }),
        ...(initialValue?.fromDate &&
          initialValue?.toDate && {
            date: [
              moment(initialValue?.fromDate),
              moment(initialValue?.toDate),
            ],
          }),
      });
    }
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    onFilter({
      methods: values?.methods,
      ...(values?.date?.[0] && {
        fromDate: moment(values?.date?.[0])?.format(DATE_BACKEND_FORMAT),
        toDate: moment(values?.date?.[1])?.format(DATE_BACKEND_FORMAT),
      }),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      fromDate: null,
      toDate: null,
      methods: null,
    };
    onFilter(values);
    onClose();
  };

  const methodOptions = Object.entries(methodsText).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select placeholder="Vui lòng chọn" options={methodOptions as any} />
        </FormTeraItem>

        <FormTeraItem label="Thời gian tạo" name="date">
          <RangePicker placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']} />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default TransactionFilter;
