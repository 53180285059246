export const CASH_RECEIPT_TYPE = {
  collect_money_customer: 'Thu tiền khách hàng',
  refund_by_supplier: 'Hoàn tiền từ nhà cung cấp',
  collect_employee_reimbursement: 'Thu hoàn ứng nhân viên',
  pay_fund: 'Nộp quỹ',
  other_revenue: 'Thu khác',
};

export const EXPENSE_VOUCHER_TYPE = {
  refund_by_customer: 'Hoàn tiền từ khách hàng',
  spend_money_supplier: 'Trả tiền nhà cung cấp',
  for_employee_advance: 'Cho nhân viên ứng',
  release_fund: 'Xuất quỹ',
  other_pay: 'Chi khác',
};

export const PAYMENT_METHOD_TYPE = {
  cash: 'Tiền mặt',
  transfer: 'Chuyển khoản',
};
