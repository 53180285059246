import Filter from '_common/component/Filter';
import DatePicker from '_common/dof/Control/DatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValues?: any;
}

const InvoiceDetailFilter = (props: IProps) => {
  const { open, onClose, onFilter, initialValues } = props;

  const form = useForm();

  useEffect(() => {
    initialValues &&
      form.reset({
        ...initialValues,
      });
  }, [initialValues]);

  const handleSubmitForm = (value): void => {
    onFilter(value);
    onClose();
  };

  const handleReset = (): void => {
    form.reset();
    onFilter({
      time: null,
      type: null,
      payment_method: null,
    });
    onClose();
  };

  return (
    <>
      <Filter
        open={open}
        onClose={onClose}
        onCancel={onClose}
        onFilter={() => form.handleSubmit(handleSubmitForm)()}
      >
        <FormTera form={form}>
          <FormTeraItem label="Loại giao dịch" name="type">
            <Select
              options={[
                {
                  value: '1',
                  label: 'Tạo mới',
                },
                {
                  value: '2',
                  label: 'Nạp tiền',
                },
                {
                  value: '3',
                  label: 'Gia hạn',
                },
              ]}
            />
          </FormTeraItem>
          <FormTeraItem label="Phương thức thanh toán" name="payment_method">
            <Select
              options={[
                {
                  value: '1',
                  label: 'Chưa xác minh',
                },
                {
                  value: '2',
                  label: 'Số dư',
                },
                {
                  value: '3',
                  label: 'Vietcombank',
                },
              ]}
            />
          </FormTeraItem>
          <FormTeraItem label="Thời gian" name="time">
            <DatePicker />
          </FormTeraItem>
          <a
            className="text-red-500 text-sm font-normal cursor-pointer"
            onClick={() => handleReset()}
          >
            Hủy bộ lọc
          </a>
        </FormTera>
      </Filter>
    </>
  );
};

export default InvoiceDetailFilter;
