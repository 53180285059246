import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import { INVOICE_RECEIPT_URL } from './url';

export const InvoiceReceiptRouter: IRouteProps[] = [
  {
    key: INVOICE_RECEIPT_URL.list.key,
    path: INVOICE_RECEIPT_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: INVOICE_RECEIPT_URL.create.key,
    path: INVOICE_RECEIPT_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: INVOICE_RECEIPT_URL.update.key,
    path: INVOICE_RECEIPT_URL.update.shortenUrl,
    component: <CreateUpdatePage />,
  },
];
