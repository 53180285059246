import CardDetail from '_common/component/CardDetail';
import FormTera from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import UserFormData from '../Form/UserFormData';
import { Button } from 'tera-dls';

const AccountInformation = () => {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    values;
  };

  useEffect(() => {
    form.setValue('is_active', true);
  }, []);

  return (
    <div className="flex flex-col gap-[16px] pb-2.5">
      <CardDetail className=" text-sm">
        <p className="text-gray-500 mb-[30px]">
          Vui lòng cập nhật đúng thông tin của bạn, để tránh các vấn đề lỗi
          thông tin khi đăng ký tên miền.
        </p>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <UserFormData isForm={false} />
        </FormTera>
      </CardDetail>
      <span>
        <Button
          onClick={form.handleSubmit(handleSubmitForm)}
          className="font-light text-[12px] bg-blue-500"
        >
          Lưu
        </Button>
      </span>
    </div>
  );
};

export default AccountInformation;
