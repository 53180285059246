import { useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
} from 'pages/InvoiceManagement/constants';
import { useContext, useEffect, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import { formatCurrency, PaginationProps } from 'tera-dls';
import { StatisticCashExpenseByCustomerParamContext } from '..';
import Header from '../../Header';
import StatisticApi from '../../StatisticCashExpense/api';
import ModalChart from './Modal';
import SettingModal from './Setting';

export type OpportunityValueStatisticByStage = 'small' | 'large';
interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  containerClassName?: string;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose, containerClassName } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const { contextParams } = useContext(
    StatisticCashExpenseByCustomerParamContext,
  );
  const isSmallType = type === 'small';

  const currentWeek = [
    moment().startOf('week').format(DATE_BACKEND_FORMAT),
    moment().endOf('week').format(DATE_BACKEND_FORMAT),
  ];

  const [params, setParams] = useState<any>({
    pay_method_type: ['cash', 'transfer'],
    time: 'currentWeek',
    accounting_date: currentWeek,
    receipt_type: Object.keys(CASH_RECEIPT_TYPE),
    expense_type: Object.keys(EXPENSE_VOUCHER_TYPE),
    date: currentWeek,
    total_from: null,
    total_to: null,
    page: 1,
    limit: isSmallType ? 8 : 15,
  });

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-by-customer', params],
    () =>
      StatisticApi.byCustomer({
        params: {
          page: params?.page,
          limit: params?.limit,
          ...(params?.pay_method_type?.[0] && {
            pay_method_type: params?.pay_method_type.join(','),
          }),
          ...(params?.accounting_date && {
            accounting_date: params?.accounting_date.join(','),
          }),
          ...(params?.type_customer && {
            type_customer: params?.type_customer,
          }),
          ...(params?.receipt_type?.[0] && {
            receipt_type: params?.receipt_type?.join(','),
          }),
          ...(params?.expense_type?.[0] && {
            expense_type: params?.expense_type?.join(','),
          }),
          ...(params?.date && {
            date: params?.date?.join(','),
          }),
          ...(params.receipt_total_from && {
            receipt_total_from: params.receipt_total_from,
          }),
          ...(params.receipt_total_to && {
            receipt_total_to: params.receipt_total_to,
          }),
          ...(params.expense_total_from && {
            expense_total_from: params.expense_total_from,
          }),
          ...(params.expense_total_to && {
            expense_total_to: params.expense_total_to,
          }),
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, [params]);

  useEffect(() => {
    contextParams && setParams(contextParams);
  }, [contextParams]);

  const columns = [
    {
      title: 'Tên khách hàng',
      dataIndex: 'customer',
      width: 200,
      render: (value) => (
        <HoverQuickView
          avatarUrl={value?.avatar_url}
          email={value?.email}
          phone={value?.phone}
          name={value?.name}
          sub={value?.customer_type_text?.title}
          code={value?.code}
        >
          <span className="line-clamp-2">{value?.name ?? ''}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Thu',
      dataIndex: 'receipt',
      width: 150,
      render: (value) => (value ? formatCurrency(value) : 0),
    },
    {
      title: 'Chi',
      dataIndex: 'expense_voucher',
      width: 150,
      render: (value) => (value ? formatCurrency(value) : 0),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams((prev) => ({ ...prev, page: page, limit: pageSize }));
  };

  return (
    <>
      <div className="rounded-[5px] border-[1px] bg-white h-full flex flex-col overflow-hidden">
        <Header
          title={'Thu chi theo khách hàng'}
          onClickRefresh={() => refetch()}
          onClickSetting={() => setOpenSetting(true)}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <div
          className={customTwMerge(
            'p-[16px] h-full ',
            !isSmallType && '[&>*:first-child]:h-full',
            containerClassName,
          )}
        >
          <TableTera
            loading={isRefetching}
            columns={columns}
            rowKey={'id'}
            data={response?.data ?? []}
            className="rounded-[5px] h-full"
            scroll={{ ...(isSmallType ? { y: 290 } : { y: 650 }) }}
            pagination={{
              onChange: handleChangePage,
              pageSize: response?.per_page,
              current: response?.current_page,
              total: response?.total,
              from: response?.from,
              to: response?.to,
              pageSizeOptions: [8, 15, 50, 100],
            }}
          />
        </div>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={(value) => setParams((prev) => ({ ...prev, ...value }))}
          defaultValues={params}
        />
      )}
    </>
  );
};

export default Content;
