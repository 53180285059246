import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import ModalAddCloudVPS from 'pages/ServiceManagement/CloudVPS/modals/ModalAddCloudVPS';
import DatacenterAPI from 'pages/ServicePackage/Datacenter/apis';
import { useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';
import { Swiper, SwiperSlide } from 'swiper/react';
import customTwMerge from 'tailwind-merge.config';
import { Spin, formatCurrency } from 'tera-dls';
import { PRICE_PERIOD_TEXT } from '../../../constants';

interface IFromCreateUpdateProps {
  form: UseFormReturn<any>;
  countryCode: string;
  dataDetails?: any;
}

const FormCreateUpdate = ({
  form,
  countryCode,
  dataDetails,
}: IFromCreateUpdateProps): JSX.Element => {
  const [isOpenModalAddCloudVPS, setIsOpenModalAddCloudVPS] =
    useState<boolean>(false);

  const pricePeriod = useMemo(
    () => PRICE_PERIOD_TEXT[form.watch('period')],
    [form.getValues()],
  );

  const LocationOptions = useMemo(
    () =>
      form.watch('product')?.locations?.map(({ location }) => ({
        value: location.locationId,
        label: location.locationName,
      })),
    [form.getValues()],
  );

  const OSOptions = useMemo(
    () =>
      form.watch('product')?.operating_systems?.map(({ os }) => ({
        value: os.osId,
        label: os.osName,
      })),
    [form.getValues()],
  );

  const {
    data: datacenterProductList,
    refetch: refetchDatacenterProductList,
    isLoading: isLoadingDatacenterProduct,
    isError: isErrorDatacenterProduct,
  } = useQuery(
    ['get-datacenter-product-list', countryCode],
    () => {
      const params = {
        country: countryCode,
      };
      return DatacenterAPI.getProductList({ params });
    },
    {
      enabled: !_.isNil(countryCode),
      staleTime: 300000,
      cacheTime: 300000,
      onSuccess: (res) => {
        console.log(res.data, dataDetails);

        if (_.isEmpty(dataDetails))
          form.reset({
            ...form.getValues(),
            datacenterProduct: res.data[0],
            product: res.data[0]?.products[0]?.product,
            locationId:
              res.data[0]?.products[0]?.product.locations[0].locationId,
            osId: res.data[0]?.products[0]?.product.operating_systems[0].osId,
          });
        else {
          form.reset({
            ...form.getValues(),
            datacenterProduct: res.data[0],
            product: dataDetails.product,
            locationId: dataDetails.locationId,
            osId: dataDetails.osId,
          });
        }
      },
    },
  );

  useEffect(() => {
    if (countryCode) refetchDatacenterProductList();
    else if (_.isNil(dataDetails?.id)) setIsOpenModalAddCloudVPS(true);
  }, [countryCode]);

  const handleSelectDatacenterProduct = (datacenterProduct: any) => {
    const product = datacenterProduct.products[0]?.product;

    form.reset(
      {
        ...form.getValues(),
        datacenterProduct: datacenterProduct,
        product: product,
        locationId: product?.locations[0].locationId,
        osId: product?.operating_systems[0].osId,
      },
      { keepDirty: true },
    );
  };

  const handleSelectProduct = (product: any) => {
    form.reset(
      {
        ...form.getValues(),
        product: product,
        locationId: product.locations[0].locationId,
        osId: product.operating_systems[0].osId,
      },
      { keepDirty: true },
    );
  };

  const formSections = [
    {
      name: 'Chọn Datacenter',
      content: () => (
        <Swiper
          spaceBetween={25}
          slidesPerView={3}
          className="!w-full !h-auto !p-4 !pt-0"
        >
          {datacenterProductList?.data.map((datacenterProduct: any) => (
            <SwiperSlide
              key={datacenterProduct.datacenterId}
              className={customTwMerge(
                'min-w-[400px] max-w-[400px] !h-auto bg-white p-6 py-8 rounded-[8px] hover:drop-shadow-xl flex flex-col justify-between cursor-pointer',
                _.isEqual(
                  form.watch('datacenterProduct').datacenterId,
                  datacenterProduct.datacenterId,
                ) && 'text-blue-800 !bg-[#EBF5FF] drop-shadow-xl',
              )}
              onClick={() => handleSelectDatacenterProduct(datacenterProduct)}
            >
              <span className="text-[30px] font-bold leading-9">
                {datacenterProduct.datacenterName}
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      ),
    },
    {
      name: 'Chọn gói dịch vụ',
      content: () => (
        <Swiper
          spaceBetween={25}
          slidesPerView={3}
          className="!w-full !h-auto !p-4 !pt-0"
        >
          {form.watch('datacenterProduct')?.products?.map(({ product }) => {
            const details = [
              {
                label: 'Dung lượng',
                value: product.config.ssd,
              },
              {
                label: 'Ram',
                value: product.config.ram,
              },
              {
                label: 'CPU',
                value: product.config.cpu,
              },
              { label: 'Backup', value: product.backup },
              {
                label: 'Bandwidth',
                value: product.config.bandwidth,
              },
              { label: 'Dedicated', value: '' },
            ];

            return (
              <SwiperSlide
                key={product.planId}
                className={customTwMerge(
                  'min-w-[400px] max-w-[400px] !h-auto bg-white p-6 py-8 rounded-[8px] hover:drop-shadow-xl flex flex-col cursor-pointer',
                  _.isEqual(form.watch('product').planId, product.planId) &&
                    'text-blue-800 !bg-[#EBF5FF] drop-shadow-xl',
                )}
                onClick={() => handleSelectProduct(product)}
              >
                <span className="text-[30px] font-bold leading-9">
                  {product.planCode}
                </span>
                <div className="pb-8 mt-[4rem] border-b-[1px] border-[#606999] border-dashed flex gap-2">
                  <span className="text-[25px] font-bold">
                    {formatCurrency(product.prices[form.watch('period')])}
                  </span>
                  <span>/</span>
                  <span>{pricePeriod}</span>
                </div>
                <div className="mt-[2rem] flex flex-col items-center gap-4">
                  {details.map((detail, index) => (
                    <div key={index} className="w-full flex gap-3">
                      <span className="flex-1 text-gray-500 text-right">
                        {detail.label}
                      </span>
                      <span className="flex-1 font-[500]">{detail.value}</span>
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ),
    },
    {
      name: 'Vị trí',
      content: () => (
        <FormTeraItem
          name="locationId"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
        >
          <Select options={LocationOptions} />
        </FormTeraItem>
      ),
    },
    {
      name: 'OS Template',
      content: () => (
        <FormTeraItem
          name="osId"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
        >
          <Select options={OSOptions} />
        </FormTeraItem>
      ),
    },
  ];

  return (
    <Spin spinning={isLoadingDatacenterProduct}>
      {isErrorDatacenterProduct || _.isEmpty(datacenterProductList?.data) ? (
        <NoData />
      ) : (
        <div className="p-2 flex flex-col gap-10">
          {formSections.map((section, index) => (
            <Fragment key={index}>
              <div className="flex items-center gap-2">
                <span className="w-[30px] h-[30px] text-blue-500 font-[500] border-[1px] border-blue-500 rounded-[30px] flex justify-center items-center">
                  {index + 1}
                </span>
                <span className="text-[20px] font-[500]">{section.name}</span>
              </div>
              {section.content()}
            </Fragment>
          ))}
        </div>
      )}
      {isOpenModalAddCloudVPS && (
        <ModalAddCloudVPS
          open={isOpenModalAddCloudVPS}
          close={() => setIsOpenModalAddCloudVPS(false)}
        />
      )}
    </Spin>
  );
};

export default FormCreateUpdate;
