import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank, { OptionsSelectBank } from '_common/dof/Select/SelectBank';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { Modal } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: number;
  onCancel: () => void;
}

const BankFormData = (props: IProps) => {
  const { open, value: id, onCancel } = props;
  const confirm = useConfirm();
  const form = useForm({ mode: 'onChange' });
  const {
    formState: { isDirty },
  } = form;

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu cấu hình.</p>
          </>
        ),
        onOk: () => {
          onCancel();
        },
      });
      return;
    }
    onCancel();
  };

  const handleSubmitForm = (values) => {
    values;
  };
  return (
    <Modal
      open={open}
      title={id ? 'Sửa thông tin thẻ' : 'Thêm thông tin thẻ'}
      closeIcon={false}
      okText="Lưu"
      cancelText="Hủy"
      width={700}
      onCancel={handleCancel}
      onOk={form.handleSubmit(handleSubmitForm)}
    >
      <FormTera form={form} isLoading={false}>
        <FormTeraItem
          name="card_type_id"
          label="Ngân hàng"
          rules={[{ required: messageValidate.emptySelect }]}
        >
          <SelectBank
            onChangeCustom={(value: OptionsSelectBank) => value}
            paramsApi={{ object_type: 1 }}
          />
        </FormTeraItem>
        <FormTeraItem
          name="account_number"
          label="Số tài khoản"
          rules={[
            {
              required: messageValidate.emptyText,
            },
            {
              pattern: {
                value: new RegExp(REGEX.CARD_NUMBER),
                message: messageValidate.format,
              },
            },
          ]}
        >
          <Input maxLength={20} />
        </FormTeraItem>
        <FormTeraItem
          name="cardholder"
          label="Người thụ hưởng"
          rules={[{ required: messageValidate.emptyText }]}
        >
          <Input className="uppercase placeholder-shown:normal-case" />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default BankFormData;
