import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  notification,
  Spin,
} from 'tera-dls';
import InvoiceAPI from '../../apis';
import { INVOICE_STATUS, TRANSACTION_TYPE } from '../../constants';
import { INVOICE_URL } from '../../url';
import InvoiceContent from './components/InvoiceContent';

const DetailPage = () => {
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(['get-invoice-detail', id], () => InvoiceAPI.getDetail({ id }), {
    enabled: !_.isNil(id),
    staleTime: 300000,
    cacheTime: 300000,
  });

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const { mutate: mutateApproval, isLoading: isLoadingApproval } = useMutation(
    (params: any) =>
      InvoiceAPI.approval({ id: params?.id, params: params?.params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-invoice-detail']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleConfirmPayment = () =>
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <span className="font-bold">{dataDetail.invoice_code}</span> này
            không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateApproval({
          id: dataDetail.id,
          params: {
            status: INVOICE_STATUS.APPROVED,
          },
        }),
    });

  return (
    <Spin spinning={isLoadingDetail || isLoadingApproval}>
      {id && isErrorDetail ? (
        <NoData />
      ) : (
        <div className="tera-page-form">
          <div className="page-header-sticky">
            <div className="page-header-v2">
              <div className="page-header-v2__breadcrumb">
                <div
                  className="page-header__breadcrumb-back cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ArrowSmallLeftSolid className="size-6" />
                </div>
                <Breadcrumb
                  separator={'/'}
                  items={[
                    {
                      title: (
                        <a className="cursor-pointer text-blue-400 italic text-sm">
                          Danh sách hóa đơn
                        </a>
                      ),
                      onClick: () => navigate(INVOICE_URL.list.path),
                    },
                    {
                      title: (
                        <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px] text-sm">
                          Chi tiết hóa đơn
                        </a>
                      ),
                    },
                  ]}
                />
              </div>
              {!_.isEqual(dataDetail?.status, INVOICE_STATUS.APPROVED) &&
                _.isEqual(
                  dataDetail?.transaction_type,
                  TRANSACTION_TYPE.PAY,
                ) && (
                  <div className="page-header-v2__function shrink-0">
                    <Button onClick={handleConfirmPayment}>
                      Xác nhận thanh toán
                    </Button>
                  </div>
                )}
            </div>
          </div>
          <div className="page-content-v2 !gap-[16px] rounded-md !px-[16px] h-full mx-2.5 !mb-2.5">
            <InvoiceContent dataDetail={dataDetail} />
          </div>
        </div>
      )}
    </Spin>
  );
};

export default DetailPage;
