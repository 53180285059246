import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvoicePaymentAPIEndpoint = `${endpoint}/admin/finance/accounting-voucher/payment`;

const InvoicePaymentAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${InvoicePaymentAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getRevenue: async () =>
    await api
      .get(`${InvoicePaymentAPIEndpoint}/revenue`)
      .then((data) => data?.data?.data),
  getCodeSuggestion: async () =>
    await api
      .get(`${InvoicePaymentAPIEndpoint}/suggest-code`)
      .then((data) => data?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvoicePaymentAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${InvoicePaymentAPIEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${InvoicePaymentAPIEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${InvoicePaymentAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
  getExplainList: async ({ id, params }) =>
    await api
      .get(`${InvoicePaymentAPIEndpoint}/explain/list/${id}`, params)
      .then((data) => data?.data?.data),
  saveExplain: async ({ params }) =>
    await api
      .put(`${InvoicePaymentAPIEndpoint}/save-explain`, params)
      .then((result) => result?.data),
  createExplain: async ({ params }) =>
    await api
      .post(`${InvoicePaymentAPIEndpoint}/explain/create`, params)
      .then((result) => result?.data),
  updateExplain: async ({ id, params }) =>
    await api
      .put(`${InvoicePaymentAPIEndpoint}/explain/update/${id}`, params)
      .then((result) => result?.data),
  deleteExplain: async ({ id }) =>
    await api
      .delete(`${InvoicePaymentAPIEndpoint}/explain/delete/${id}`)
      .then((result) => result?.data),
};

export default InvoicePaymentAPI;
