import { FormTeraItem } from '_common/dof/FormTera';
import SelectCustomer from '_common/dof/Select/SelectCustomer';

const FormCustomer = (): JSX.Element => {
  const details = [
    { label: 'Tên công ty', value: 'AutoLoad' },
    { label: 'Mã số thuế', value: 'AutoLoad' },
    { label: 'Tên', value: 'AutoLoad' },
    { label: 'Họ và tên đệm', value: 'AutoLoad' },
    { label: 'Danh xưng', value: 'AutoLoad' },
    { label: 'Số CCCD/Passport', value: 'AutoLoad' },
    { label: 'Email', value: 'AutoLoad' },
    { label: 'Điện thoại', value: 'AutoLoad' },
    { label: 'Quốc gia', value: 'AutoLoad' },
    { label: 'Tỉnh/Thành', value: 'AutoLoad' },
    { label: 'Quận/Huyện', value: 'AutoLoad' },
    { label: 'Phường/Xã', value: 'AutoLoad' },
    { label: 'Địa chỉ', value: 'AutoLoad' },
    { label: 'Email nhận hóa đơn', value: 'AutoLoad' },
  ];

  return (
    <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-6">
      <span className="text-blue-500">Thông tin khách hàng</span>
      <FormTeraItem name="registrant" label="Người dùng" className="mb-0">
        <SelectCustomer
          options={[
            {
              value: 1,
              label: 'Tên người dùng',
              labelDisplay: (
                <div className="flex items-center gap-1">
                  <span className="text-green-500">[Mã người dùng]</span>
                  <span>Tên người dùng</span>
                </div>
              ),
            },
            {
              value: 2,
              label: 'Tên người dùng',
              labelDisplay: (
                <div className="flex items-center gap-1">
                  <span className="text-green-500">[Mã người dùng]</span>
                  <span>Tên người dùng</span>
                </div>
              ),
            },
            {
              value: 3,
              label: 'Tên người dùng',
              labelDisplay: (
                <div className="flex items-center gap-1">
                  <span className="text-green-500">[Mã người dùng]</span>
                  <span>Tên người dùng</span>
                </div>
              ),
            },
            {
              value: 4,
              label: 'Tên người dùng',
              labelDisplay: (
                <div className="flex items-center gap-1">
                  <span className="text-green-500">[Mã người dùng]</span>
                  <span>Tên người dùng</span>
                </div>
              ),
            },
          ]}
          allowClear
        />
      </FormTeraItem>
      <div className="flex flex-wrap">
        {details.map((detail, index) => (
          <div key={index} className="w-[50%] py-4 border-b-[1px]">
            <span className="w-[150px] text-gray-500 inline-block">
              {detail.label}
            </span>
            <span>{detail.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormCustomer;
