import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectLocation from '_common/dof/Select/SelectLocation';
import SelectOperatingSystem from '_common/dof/Select/SelectOperatingSystem';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Spin, formatDate } from 'tera-dls';
import CloudVPSAPI from '../apis';
import { deleteOptions } from '../constants';

interface IModalChangeIPProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalChangeIP = ({
  open,
  close,
  id,
}: IModalChangeIPProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { locationId: '', osId: '', delete_option: 1 },
  });
  const confirm = useConfirm();

  const {
    data: dataDetails,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => CloudVPSAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const handleCloseConfirm = () => {
    confirm.warning({
      title: 'Thoát bản ghi',
      content: (
        <>
          <p>{messageWarning.WARNING_EXIT_1}</p>
          <p>{messageWarning.WARNING_EXIT_2}</p>
        </>
      ),
      onOk: () => close(),
    });
  };

  const details = [
    {
      label: 'Địa chỉ IP',
      value: dataDetails?.ip,
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày hết hạn',
      value: '',
    },
    {
      label: 'Vị trí',
      value: dataDetails?.location.locationName,
    },
    {
      label: 'Hệ điều hành',
      value: dataDetails?.os.osName,
    },
    {
      label: 'Ghi chú',
      value: 'Note',
    },
  ];

  return (
    <Modal
      title="Đổi IP dịch vụ"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={close}
      onCancel={handleCloseConfirm}
      className="md:w-[500px]"
      bodyClassName="!pb-2"
    >
      <Spin spinning={id && isLoadingDetail}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <div className="py-2 flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              {details.map((detail, index) => (
                <div key={index} className="flex">
                  <span className="w-[50%] text-[#6B7280]">{detail.label}</span>
                  <span className="w-[50%]">{detail.value}</span>
                </div>
              ))}
            </div>
            <FormTera form={form} className="flex flex-col gap-2">
              <FormTeraItem label="Vị trí" name="location" className="mb-0">
                <SelectLocation
                  paramsApi={{ country: '' }}
                  allowClear={false}
                />
              </FormTeraItem>
              <FormTeraItem
                label="Cài lại hệ điều hành"
                name="os"
                className="mb-0"
              >
                <SelectOperatingSystem allowClear={false} />
              </FormTeraItem>
              <FormTeraItem
                label="Xóa dữ liệu"
                name="delete_option"
                className="mb-0"
              >
                <Select options={deleteOptions} />
              </FormTeraItem>
            </FormTera>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalChangeIP;
