import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, Spin } from 'tera-dls';
import CloudVPSAPI from '../../apis';
import { SERVICE_CLOUD_VPS_URL } from '../../url';
import CurrentVPS from './components/CurrentVPS';
import ReinstallOS from './components/ReinstallOS';

const ReinstallOSVPSPage = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () => navigate(SERVICE_CLOUD_VPS_URL.list.path),
    },
    {
      title: 'Cài lại hệ điều hành cho dịch vụ',
    },
  ];

  const {
    data: dataDetails,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => CloudVPSAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <Spin spinning={isLoadingDetail}>
        {isErrorDetail || _.isEmpty(dataDetails) ? (
          <NoData />
        ) : (
          <div className="px-2 flex items-start gap-4">
            <CurrentVPS dataDetails={dataDetails} />
            <ReinstallOS />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default ReinstallOSVPSPage;
