import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvoiceReceiptAPIEndpoint = `${endpoint}/admin/finance/accounting-voucher/receipt`;

const InvoiceReceiptAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${InvoiceReceiptAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getRevenue: async () =>
    await api
      .get(`${InvoiceReceiptAPIEndpoint}/revenue`)
      .then((data) => data?.data?.data),
  getCodeSuggestion: async () =>
    await api
      .get(`${InvoiceReceiptAPIEndpoint}/suggest-code`)
      .then((data) => data?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvoiceReceiptAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${InvoiceReceiptAPIEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${InvoiceReceiptAPIEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${InvoiceReceiptAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
  getExplainList: async ({ id, params }) =>
    await api
      .get(`${InvoiceReceiptAPIEndpoint}/explain/list/${id}`, params)
      .then((data) => data?.data?.data),
  saveExplain: async ({ params }) =>
    await api
      .put(`${InvoiceReceiptAPIEndpoint}/save-explain`, params)
      .then((result) => result?.data),
  createExplain: async ({ params }) =>
    await api
      .post(`${InvoiceReceiptAPIEndpoint}/explain/create`, params)
      .then((result) => result?.data),
  updateExplain: async ({ id, params }) =>
    await api
      .put(`${InvoiceReceiptAPIEndpoint}/explain/update/${id}`, params)
      .then((result) => result?.data),
  deleteExplain: async ({ id }) =>
    await api
      .delete(`${InvoiceReceiptAPIEndpoint}/explain/delete/${id}`)
      .then((result) => result?.data),
};

export default InvoiceReceiptAPI;
