export enum INVOICE_STATUS {
  PENDING = 'pending',
  APPROVED = 'approved',
  ERROR = 'error',
  fail = 'fail',
}
export enum TRANSACTION_TYPE {
  PAY = 'pay',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}
export const TABLE_STATUS_COLOR = {
  pending: 'yellow03',
  approved: 'green03',
  error: 'yellow03',
  fail: 'red03',
};
export const TABLE_STATUS_TEXT = {
  pending: 'Chưa thanh toán',
  approved: 'Hoàn thành',
  error: 'Chưa thanh toán',
  fail: 'Thất bại',
};
export const TRANSACTION_TEXT = {
  pay: 'Thanh toán',
  deposit: 'Nạp tiền',
  withdraw: 'Rút tiền',
};
export const PAYMENT_METHOD_TEXT = {
  transfer: 'Chuyển khoản',
  cash: 'Tiền mặt',
  wallet: 'Ví SUMI',
  momo: 'Ví điện tử',
};
