import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import UploadFiles from '_common/dof/UploadFiles';
import _ from 'lodash';
import moment from 'moment';
import { renderDescription } from 'pages/InvoiceManagement/utils';
import { useEffect, useState } from 'react';
import { Checkbox, Modal, Spin } from 'tera-dls';
import DatacenterAPI from '../apis';
import ExplainTable from '../components/ExplainTable';
import { PAYMENT_METHOD_TEXT } from '../constants';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalDetail = ({
  open,
  close,
  id,
}: IModalModalCreateUpdateProps): JSX.Element => {
  const [total, setTotal] = useState<number>(0);

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-invoice-payment-detail', id],
    () => DatacenterAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const detail = [
    {
      key: '1',
      title: 'Người nộp',
      value: dataDetail?.author,
    },
    {
      key: '6',
      title: 'Phương thức thanh toán',
      value: PAYMENT_METHOD_TEXT[dataDetail?.methods],
    },
    {
      key: '2',
      title: 'Ngày tạo phiếu chi',
      value:
        dataDetail?.date && moment(dataDetail?.date).format(DATE_TIME_FORMAT),
    },
    {
      key: '4',
      title: 'Ngày hạch toán',
      value:
        dataDetail?.accounting_date &&
        moment(dataDetail?.accounting_date).format(DATE_TIME_FORMAT),
    },
  ];

  return (
    <Modal
      title="Chi tiết phiếu thu"
      open={open}
      closeIcon={false}
      centered={true}
      destroyOnClose
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={close}
      contentClassName="md:w-[800px] h-full"
      bodyClassName="!pb-2"
    >
      <Spin spinning={id && isLoadingDetail}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2.5 my-2.5">
              <div className="font-medium text-base text-center break-all ">
                Phiếu{' '}
                <span className="lowercase">
                  {dataDetail?.ballot_type_text}
                </span>
              </div>
              <div className="font-normal text-center break-all">
                Mã phiếu chi: {dataDetail?.code ?? ''}
              </div>
            </div>
            <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
              Thông tin chung
            </div>
            <div className="grid gap-y-2.5 gap-x-5">
              <div className="grid grid-cols-2 gap-y-2.5 gap-x-5">
                {renderDescription(detail, 140)}
              </div>
              <div className="grid grid-cols-1 gap-y-2.5 gap-x-5">
                {renderDescription(
                  [
                    {
                      key: 'd1',
                      title: 'Ghi chú',
                      value: dataDetail?.note ?? '',
                    },
                  ],
                  140,
                )}
              </div>
            </div>
            <div>
              <Checkbox
                checked={dataDetail?.is_check}
                className="pointer-events-none"
                labelClassName="font-normal text-[13px] leading-[16px] pointer-events-none"
              >
                Hạch toán vào kết quả kinh doanh
              </Checkbox>
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
                Danh sách hạch toán {total}
              </div>
              <ExplainTable
                type="expense-voucher"
                mode="view"
                id={dataDetail?.id}
                onTotalChange={setTotal}
                objectType="expense-voucher"
              />
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
                File đính kèm
              </div>
              <UploadFiles
                mode={'view'}
                isCount={false}
                object_key="expense-voucher"
                folder="expense-voucher"
                fileList={dataDetail?.files?.map((item) => ({
                  id: item.id,
                  name: item.file_name,
                  url: item.link_file,
                }))}
                className="max-w-max"
              />
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalDetail;
