import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import { TParamsApiDof } from '../../interfaces';
import SelectEntity from '../SelectEntity';
import { EmployeeText } from '_common/component/TableColumnCustom/EmployeeText';

export interface SelectDataTypeProps extends SelectProps {
  paramsApi?: TParamsApiDof;
  optionCustom?: OptionProps[];
  isCheckAll?: boolean;
}

const SelectCustomer = forwardRef<HTMLSelectElement, SelectDataTypeProps>(
  (
    { isCheckAll = false, optionCustom = [], paramsApi, mode, ...props },
    ref,
  ) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data } = useQuery(
      ['get-customer-management-list', searchDebounce, paramsApi],
      () => {
        return { data: [] };
      },
      {
        enabled: !props?.disabled,
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionColumnConfig: OptionProps[] = useMemo(() => {
      const options = data?.data?.map((item) => ({
        labelDisplay:
          mode === 'multiple' ? (
            item?.name
          ) : (
            <EmployeeText code={item?.code} name={item?.name} />
          ),
        label:
          mode === 'multiple' ? item?.name : `${item?.code} - ${item?.name}`,
        value: item?.id,
      }));
      return options || [];
    }, [data]);

    return (
      <SelectEntity
        ref={ref}
        mode={mode}
        allowClear
        showSearch
        labelInValue
        options={[...optionCustom, ...optionColumnConfig]}
        searchValue={search}
        onSearch={setSearch}
        isCheckAll={isCheckAll}
        placeholder="Vui lòng chọn"
        {...props}
      />
    );
  },
);

export default SelectCustomer;
