import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const PaymentMethodEndpoint = `${endpoint}/administrator/payment-method`;
const WalletEndpoint = `/portal/wallet`;

const ConfigPaymentMethodApi = {
  getList: async (params?) =>
    await api
      .get(`${PaymentMethodEndpoint}/list`, params)
      .then((result) => result?.data?.data),
  getTransactionDetail: async (params?) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-detail`, params)
      .then((result) => result?.data?.data),
  getChart: async (params?) =>
    await api
      .get(`${PaymentMethodEndpoint}/chart`, params)
      .then((result) => result?.data?.data),
  getTransactionAll: async (params?) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-all`, params)
      .then((result) => result?.data?.data),
  getTransactionFind: async (id: number) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-find/${id}`)
      .then((result) => result?.data?.data),
  getTransactionUpdate: async (id: number, params: any) =>
    await api
      .put(`${PaymentMethodEndpoint}/transaction-update/${id}`, params)
      .then((result) => result?.data),
  transaction: async (params: any) =>
    await api
      .post(`${PaymentMethodEndpoint}/transaction`, params)
      .then((result) => result?.data),
  getDetail: async (id: string | number) =>
    await api
      .get(`${PaymentMethodEndpoint}/card-detail/${id}`)
      .then((result) => result.data?.data),
  create: async (params?) =>
    await api
      .post(`${PaymentMethodEndpoint}/create`, params)
      .then((result) => result.data),
  update: async (id: string | number, params?) =>
    await api
      .put(`${PaymentMethodEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async (id: string | number) =>
    await api
      .delete(`${PaymentMethodEndpoint}/delete/${id}`)
      .then((result) => result.data),
  getQrCode: async (params) =>
    await api
      .get(`${WalletEndpoint}/get-qr`, params)
      .then((result) => result?.data?.data?.data),
};

export default ConfigPaymentMethodApi;
