import TeraFilter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  PAYMENT_METHOD_TEXT,
  TABLE_STATUS_TEXT,
  TRANSACTION_TEXT,
} from '../constants';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: IProps) {
  const form = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (initialValue) {
      form.reset({
        ...initialValue,
        created_at: initialValue.created_at
          ? moment(initialValue.created_at)
          : '',
      });
    }
  }, [initialValue]);

  const handleSubmitForm = (values: any) => {
    onFilter({
      ...values,
      created_at: values.created_at
        ? moment(values.created_at).format(DATE_BACKEND_FORMAT)
        : '',
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      transaction_type: '',
      status: '',
      methods: '',
      created_at: '',
    };
    onFilter(values);
    onClose();
  };

  const statusOptions = Object.entries(TABLE_STATUS_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const transactionOptions = Object.entries(TRANSACTION_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const methodOptions = Object.entries(PAYMENT_METHOD_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={transactionOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select options={methodOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Trạng thái" name="status">
          <Select options={statusOptions} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngày tạo" name="created_at">
          <DatePicker format={DATE_FORMAT} allowClear />
        </FormTeraItem>
        <span className="text-red-500 cursor-pointer" onClick={handleReset}>
          Hủy bộ lọc
        </span>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
