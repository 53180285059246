export const USER_INFORMATION_URL = {
  list: {
    key: 'user-information-list',
    path: '/user/user-information/list',
    shortenUrl: 'user-information/list',
  },
  create: {
    key: 'user-information-create',
    path: '/user/user-information/create',
    shortenUrl: 'user-information/create',
  },
  update: {
    key: 'user-information-update',
    path: '/user/user-information/update',
    shortenUrl: 'user-information/update/:id',
  },
  detail: {
    key: 'user-information-detail',
    path: '/user/user-information/detail',
    shortenUrl: 'user-information/detail/:id',
  },
  invoiceTemplate: {
    key: 'user-information-detail-invoice-template',
    path: '/user/user-information/invoice-template',
    shortenUrl: 'user-information/invoice-template/:detailId/:id',
  },
};
