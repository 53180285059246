import { messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { ColumnsType, Modal, Spin, formatCurrency } from 'tera-dls';

const dataPrices = [
  { id: 1, ip: '103.250.78.65', price: 1075000 },
  { id: 2, ip: '103.250.78.65', price: 1075000 },
  { id: 3, ip: '103.250.78.65', price: 1075000 },
  { id: 4, ip: '103.250.78.65', price: 1075000 },
];

interface IModalExtendProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalExtend = ({ open, close }: IModalExtendProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { duration: '30days' },
  });
  const confirm = useConfirm();

  const handleCloseConfirm = () => {
    confirm.warning({
      title: 'Thoát bản ghi',
      content: (
        <>
          <p>{messageWarning.WARNING_EXIT_1}</p>
          <p>{messageWarning.WARNING_EXIT_2}</p>
        </>
      ),
      onOk: () => close(),
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'IP',
      dataIndex: 'ip',
      render: (ip) => <span className="text-green-500">{ip}</span>,
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      render: (price) => (
        <span className="text-red-500">{formatCurrency(price)}</span>
      ),
    },
  ];

  return (
    <Modal
      title="Gia hạn VPS"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={close}
      onCancel={handleCloseConfirm}
      className="md:w-[500px]"
      bodyClassName="!pb-2"
    >
      <Spin spinning={false}>
        <FormTera form={form} className="flex flex-col gap-2">
          <FormTeraItem label="Mã giảm giá" name="promo_code" className="mb-0">
            <Input placeholder="Vui lòng nhập (Nếu có)" />
          </FormTeraItem>
          <FormTeraItem
            label="Thời hạn (Ngày)"
            name="duration"
            className="mb-0"
          >
            <Select
              options={[
                { value: '30days', label: '30 ngày' },
                { value: '60days', label: '60 ngày' },
              ]}
            />
          </FormTeraItem>
          <FormTeraItem
            label="Thành tiền"
            name="price"
            className="text-red-500 mb-0"
          >
            <Input defaultValue={formatCurrency(1075000)} disabled />
          </FormTeraItem>
          <span className="text-red-500">Không đủ số dư để gia hạn</span>
        </FormTera>
        <TableTera columns={columns} data={dataPrices} className="mt-2" />
      </Spin>
    </Modal>
  );
};

export default ModalExtend;
