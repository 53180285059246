import CardDetail from '_common/component/CardDetail';
import DefaultImage from '_common/component/DefaultImage';
import { Col } from 'tera-dls';

const Overview = () => {
  return (
    <div className="flex flex-col gap-[16px]">
      <CardDetail title="Tài khoản của tôi">
        <div className="flex items-center gap-[30px]">
          <div className="flex gap-[30px] items-center ml-2.5">
            <DefaultImage
              src="https://static.artzone.ai/media/53335/conversions/QGUEaajd4NxjZ7gQbeNWxqNlj5O8d62eI5fLZzd3-w768.webp"
              alt=""
              className="size-[100px] rounded-full"
            />
            <Col className="col-span-1 flex-col gap-[16px] flex ">
              <div className="font-medium  text-[20px] mt-2 leading-[24px] text-center">
                hamy
              </div>
            </Col>
          </div>
        </div>
      </CardDetail>
    </div>
  );
};

export default Overview;
