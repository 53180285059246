import TeraFilter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCustomer from '_common/dof/Select/SelectCustomer';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface FilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (values: any) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: FilterProps) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      date: null,
      customer: 1,
    },
  });

  useEffect(() => {
    const values = _.pick(initialValue, ['date', 'vehicle_from', 'vehicle_to']);

    form.reset({
      ...values,
      date: values?.date ? moment(values?.date, DATE_BACKEND_FORMAT) : null,
    });
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      ...values,
      date: values?.date
        ? moment(values?.date).format(DATE_BACKEND_FORMAT)
        : null,
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      date: null,
      vehicle_from: null,
      vehicle_to: null,
    };
    onFilter(values);
    onClose();
  };

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        className="flex flex-col gap-2"
      >
        <FormTeraItem
          label="Thời gian đăng ký"
          name="registration_date"
          className="mb-0"
        >
          <DatePicker placeholder="Vui lòng chọn" format="DD/MM/YYYY" />
        </FormTeraItem>
        <FormTeraItem label="Ngày hết hạn" name="expired_date" className="mb-0">
          <DatePicker placeholder="Vui lòng chọn" format="DD/MM/YYYY" />
        </FormTeraItem>
        <FormTeraItem label="Người dùng" name="customer" className="mb-0">
          <SelectCustomer
            options={[
              { value: 1, label: 'Tất cả' },
              {
                value: 2,
                label: 'Tên người dùng',
                labelDisplay: (
                  <div className="flex items-center gap-1">
                    <span className="text-green-500">[Mã người dùng]</span>
                    <span>Tên người dùng</span>
                  </div>
                ),
              },
              {
                value: 3,
                label: 'Tên người dùng',
                labelDisplay: (
                  <div className="flex items-center gap-1">
                    <span className="text-green-500">[Mã người dùng]</span>
                    <span>Tên người dùng</span>
                  </div>
                ),
              },
              {
                value: 4,
                label: 'Tên người dùng',
                labelDisplay: (
                  <div className="flex items-center gap-1">
                    <span className="text-green-500">[Mã người dùng]</span>
                    <span>Tên người dùng</span>
                  </div>
                ),
              },
              {
                value: 5,
                label: 'Tên người dùng',
                labelDisplay: (
                  <div className="flex items-center gap-1">
                    <span className="text-green-500">[Mã người dùng]</span>
                    <span>Tên người dùng</span>
                  </div>
                ),
              },
            ]}
            allowClear
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
