import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  notification,
} from 'tera-dls';
import InvoiceAPI from '../apis';
import { PAYMENT_METHOD_TEXT, RECEIPT_TYPE_TEXT } from '../constants';
import ModalDetail from '../modals/ModalDetail';
import { INVOICE_RECEIPT_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [isOpenModalDetail, setIsOpenModalDetail] = useState<IOpenModalState>({
    isOpen: false,
    id: null,
  });
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (params: any) => InvoiceAPI.delete({ id: params?.id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-invoice-receipt-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) => {
    confirm.warning({
      title: 'Xác nhận xóa hóa đơn',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa hóa đơn</p>
          <p>
            <span className="font-bold">{record.code}</span> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateDelete({
          id: record.id,
        }),
    });
  };

  const itemActions = (item: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => setIsOpenModalDetail({ isOpen: true, id: item.id }),
      },
      {
        key: '2',
        label: 'Sửa',
        onClick: () => {
          navigate(`${INVOICE_RECEIPT_URL.update.path}/${item?.id}`);
        },
      },
      {
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(item),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã phiếu thu',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Người nộp',
      dataIndex: 'author',
      width: 150,
      render: (author, record) => (
        <HoverQuickView
          // avatarUrl={value?.avatar_url}
          email={record?.customer_email}
          name={author}
          phone={record?.customer_phone}
          className="line-clamp-2"
        >
          {author}
        </HoverQuickView>
      ),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'methods',
      width: 150,
      render: (methods) => PAYMENT_METHOD_TEXT[methods],
    },
    {
      title: 'Loại phiếu thu',
      dataIndex: 'transaction_type',
      width: 150,
      render: (transaction_type) => RECEIPT_TYPE_TEXT[transaction_type],
    },
    {
      title: 'Ngày hạch toán',
      dataIndex: 'accounting_date',
      width: 150,
      render: (accounting_date) =>
        formatDate(accounting_date, DATE_TIME_FORMAT),
    },
    {
      title: 'Ngày tạo phiếu thu',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'amount',
      width: 150,
      render: (amount: number) => formatCurrency(amount),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading || isLoadingDelete}
        className="[&_.tera-table-cell]:align-middle"
        {...props}
      />
      {isOpenModalDetail && (
        <ModalDetail
          open={isOpenModalDetail.isOpen}
          close={() => setIsOpenModalDetail({ isOpen: false, id: null })}
          id={isOpenModalDetail.id}
        />
      )}
    </Fragment>
  );
};

export default Table;
