import CopyClipboardText from '_common/component/CopyClipboardText';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';

interface ILoginInformationProps {
  dataDetails: any;
}

const LoginInformation = ({
  dataDetails,
}: ILoginInformationProps): JSX.Element => {
  const details = [
    {
      icon: <ZingIcon.IconLocationSolid color="#3f83f8" />,
      label: 'IP Port:',
      value: dataDetails?.ip,
    },
    {
      icon: <ZingIcon.IconShield />,
      label: 'Username:',
      value: dataDetails?.userName,
    },
    {
      icon: <ZingIcon.IconKey />,
      label: 'Default password:',
      value: dataDetails?.defaultPassword,
    },
  ];

  return (
    <div className="w-full flex flex-col gap-2">
      <IconText prefix={<ZingIcon.IconPersonShield />}>
        <span className="font-[500]">Credentials:</span>
      </IconText>
      <div className="grid grid-cols-2">
        {details.map((detail, index) => (
          <IconText
            key={index}
            prefix={detail.icon}
            className="py-4 border-b-[1px]"
          >
            <span className="text-blue-500">{detail.label}</span>
            <CopyClipboardText valueCopy={detail.value} tooltipPlacement="top">
              <u className="text-black font-[500]">{detail.value}</u>
            </CopyClipboardText>
          </IconText>
        ))}
      </div>
    </div>
  );
};

export default LoginInformation;
