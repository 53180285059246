import { IRouteProps } from '_common/interface/router';
import { CONFIG_PAYMENT_METHOD_URL } from './url';
import PaymentMethod from '.';
import PaymentMethodForm from './containers/Form';
import TransactionHistory from './containers/TransactionHistory';

export const ConfigPaymentMethodRouter: IRouteProps[] = [
  {
    key: CONFIG_PAYMENT_METHOD_URL.list.key,
    path: CONFIG_PAYMENT_METHOD_URL.list.shortenUrl,
    component: <PaymentMethod />,
  },
  {
    key: CONFIG_PAYMENT_METHOD_URL.create.key,
    path: CONFIG_PAYMENT_METHOD_URL.create.shortenUrl,
    component: <PaymentMethodForm />,
  },
  {
    key: CONFIG_PAYMENT_METHOD_URL.update.key,
    path: CONFIG_PAYMENT_METHOD_URL.update.shortenUrl,
    component: <PaymentMethodForm />,
  },
  {
    key: CONFIG_PAYMENT_METHOD_URL.transactionHistory.key,
    path: CONFIG_PAYMENT_METHOD_URL.transactionHistory.shortenUrl,
    component: <TransactionHistory />,
  },
];
