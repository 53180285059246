import { ReactComponent as IconArrowCache } from 'styles/images/Icons/IconArrowCache.svg';
import { ReactComponent as IconArrowRestart } from 'styles/images/Icons/IconArrowRestart.svg';
import { ReactComponent as IconBuildingColor } from 'styles/images/Icons/IconBuildingColor.svg';
import { ReactComponent as IconCPU } from 'styles/images/Icons/IconCPU.svg';
import { ReactComponent as IconCPUStorage } from 'styles/images/Icons/IconCPUStorage.svg';
import { ReactComponent as IconCalendarCheck } from 'styles/images/Icons/IconCalendarCheck.svg';
import { ReactComponent as IconCalendarEmpty } from 'styles/images/Icons/IconCalendarEmpty.svg';
import { ReactComponent as IconClose } from 'styles/images/Icons/IconClose.svg';
import { ReactComponent as IconCloseCircle } from 'styles/images/Icons/IconCloseCircle.svg';
import { ReactComponent as IconCompass } from 'styles/images/Icons/IconCompass.svg';
import { ReactComponent as IconDollar } from 'styles/images/Icons/IconDollar.svg';
import { ReactComponent as IconDollarCard } from 'styles/images/Icons/IconDollarCard.svg';
import { ReactComponent as IconEarth } from 'styles/images/Icons/IconEarth.svg';
import { ReactComponent as IconExit } from 'styles/images/Icons/IconExit.svg';
import { ReactComponent as IconFacebook } from 'styles/images/Icons/IconFacebook.svg';
import { ReactComponent as IconFloppyDisk } from 'styles/images/Icons/IconFloppyDisk.svg';
import { ReactComponent as IconGoogle } from 'styles/images/Icons/IconGoogle.svg';
import { ReactComponent as IconHourglass } from 'styles/images/Icons/IconHourglass.svg';
import { ReactComponent as IconInformation } from 'styles/images/Icons/IconInformation.svg';
import { ReactComponent as IconKey } from 'styles/images/Icons/IconKey.svg';
import { ReactComponent as IconLightning } from 'styles/images/Icons/IconLightning.svg';
import { ReactComponent as IconLocation } from 'styles/images/Icons/IconLocation.svg';
import { ReactComponent as IconLocationSolid } from 'styles/images/Icons/IconLocationSolid.svg';
import { ReactComponent as IconLoginLock } from 'styles/images/Icons/IconLoginLock.svg';
import { ReactComponent as IconLoginUser } from 'styles/images/Icons/IconLoginUser.svg';
import { ReactComponent as IconMenuBill } from 'styles/images/Icons/IconMenuBill.svg';
import { ReactComponent as IconMenuChartPie } from 'styles/images/Icons/IconMenuChartPie.svg';
import { ReactComponent as IconMenuCloud } from 'styles/images/Icons/IconMenuCloud.svg';
import { ReactComponent as IconMenuCommission } from 'styles/images/Icons/IconMenuCommission.svg';
import { ReactComponent as IconMenuDatabase } from 'styles/images/Icons/IconMenuDatabase.svg';
import { ReactComponent as IconMenuDollar } from 'styles/images/Icons/IconMenuDollar.svg';
import { ReactComponent as IconMenuHosting } from 'styles/images/Icons/IconMenuHosting.svg';
import { ReactComponent as IconMenuHouse } from 'styles/images/Icons/IconMenuHouse.svg';
import { ReactComponent as IconMenuLock } from 'styles/images/Icons/IconMenuLock.svg';
import { ReactComponent as IconMenuLogout } from 'styles/images/Icons/IconMenuLogout.svg';
import { ReactComponent as IconMenuMail } from 'styles/images/Icons/IconMenuMail.svg';
import { ReactComponent as IconMenuPerson } from 'styles/images/Icons/IconMenuPerson.svg';
import { ReactComponent as IconMenuPersonPlus } from 'styles/images/Icons/IconMenuPersonPlus.svg';
import { ReactComponent as IconMenuPromoCode } from 'styles/images/Icons/IconMenuPromoCode.svg';
import { ReactComponent as IconMenuReceipt } from 'styles/images/Icons/IconMenuReceipt.svg';
import { ReactComponent as IconMenuReceiptPayment } from 'styles/images/Icons/IconMenuReceiptPayment.svg';
import { ReactComponent as IconMenuRecharge } from 'styles/images/Icons/IconMenuRecharge.svg';
import { ReactComponent as IconMenuSetting } from 'styles/images/Icons/IconMenuSetting.svg';
import { ReactComponent as IconMenuShield } from 'styles/images/Icons/IconMenuShield.svg';
import { ReactComponent as IconMenuWifi } from 'styles/images/Icons/IconMenuWifi.svg';
import { ReactComponent as IconMenuWithdrawal } from 'styles/images/Icons/IconMenuWithdrawal.svg';
import { ReactComponent as IconMenuWorld } from 'styles/images/Icons/IconMenuWorld.svg';
import { ReactComponent as IconMessenger } from 'styles/images/Icons/IconMessenger.svg';
import { ReactComponent as IconNetwork } from 'styles/images/Icons/IconNetwork.svg';
import { ReactComponent as IconNote } from 'styles/images/Icons/IconNote.svg';
import { ReactComponent as IconPersonShield } from 'styles/images/Icons/IconPersonShield.svg';
import { ReactComponent as IconQuestionMark } from 'styles/images/Icons/IconQuestionMark.svg';
import { ReactComponent as IconRam } from 'styles/images/Icons/IconRam.svg';
import { ReactComponent as IconRecycle } from 'styles/images/Icons/IconRecycle.svg';
import { ReactComponent as IconSettings } from 'styles/images/Icons/IconSettings.svg';
import { ReactComponent as IconShield } from 'styles/images/Icons/IconShield.svg';
import { ReactComponent as IconSign } from 'styles/images/Icons/IconSign.svg';
import { ReactComponent as IconSquareBox } from 'styles/images/Icons/IconSquareBox.svg';
import { ReactComponent as IconSquarePlus } from 'styles/images/Icons/IconSquarePlus.svg';
import { ReactComponent as IconSquaresBox } from 'styles/images/Icons/IconSquaresBox.svg';
import { ReactComponent as IconTelegram } from 'styles/images/Icons/IconTelegram.svg';
import { ReactComponent as IconTools } from 'styles/images/Icons/IconTools.svg';
import { ReactComponent as IconVPS } from 'styles/images/Icons/IconVPS.svg';
import { ReactComponent as IconWifiVertical } from 'styles/images/Icons/IconWifiVertical.svg';
import { ReactComponent as IconWindows } from 'styles/images/Icons/IconWindows.svg';
import { ReactComponent as IconWorldWide } from 'styles/images/Icons/IconWorldWide.svg';
import { ReactComponent as IconCloud } from 'styles/images/Icons/cloud-arrow-up.svg';

export default {
  IconArrowCache,
  IconArrowRestart,
  IconBuildingColor,
  IconCalendarCheck,
  IconCalendarEmpty,
  IconClose,
  IconCloseCircle,
  IconCloud,
  IconCompass,
  IconCPU,
  IconCPUStorage,
  IconDollar,
  IconDollarCard,
  IconEarth,
  IconExit,
  IconFacebook,
  IconFloppyDisk,
  IconGoogle,
  IconHourglass,
  IconInformation,
  IconKey,
  IconLightning,
  IconLocation,
  IconLocationSolid,
  IconLoginLock,
  IconLoginUser,
  IconMenuBill,
  IconMenuChartPie,
  IconMenuCloud,
  IconMenuCommission,
  IconMenuDatabase,
  IconMenuDollar,
  IconMenuHosting,
  IconMenuHouse,
  IconMenuLock,
  IconMenuLogout,
  IconMenuMail,
  IconMenuPerson,
  IconMenuPersonPlus,
  IconMenuPromoCode,
  IconMenuReceipt,
  IconMenuReceiptPayment,
  IconMenuRecharge,
  IconMenuSetting,
  IconMenuShield,
  IconMenuWifi,
  IconMenuWithdrawal,
  IconMenuWorld,
  IconMessenger,
  IconNetwork,
  IconNote,
  IconPersonShield,
  IconQuestionMark,
  IconRam,
  IconRecycle,
  IconSettings,
  IconShield,
  IconSign,
  IconSquareBox,
  IconSquarePlus,
  IconSquaresBox,
  IconTelegram,
  IconTools,
  IconVPS,
  IconWifiVertical,
  IconWindows,
  IconWorldWide,
};
