import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { ImportExportType } from '_common/component/ImportExport/constants';
import { download } from '_common/component/ImportExport/util';
import ModalImport from '_common/component/ModalImport';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ImportExportApi from 'states/api/ImportExportApi';
import {
  Button,
  PaginationProps,
  PlusOutlined,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';
import InvoiceReceiptAPI from './apis';
import Filter from './components/Filter';
import Search from './components/Search';
import Statistic from './components/Statistic';
import Table from './components/Table';
import { INVOICE_PAYMENT_URL } from './url';

const ListPage = () => {
  const [isOpenModalImport, setIsOpenModalImport] = useState<boolean>(false);
  const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const queryClient = useQueryClient();

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVOICE_PAYMENT_URL.list.path}${paramString}`);
  };

  const handleSearch = ({ keyword }): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const {
    data: dataList,
    isLoading: isLoadingDataList,
    refetch: refetchDataList,
  } = useQuery(
    ['get-invoice-payment-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return InvoiceReceiptAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const { mutate: mutateExport } = useMutation(
    (variables: any) => ImportExportApi.export(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          setSelectedRowKeys([]);
          download(res?.data?.src, ImportExportType.receiptItem);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { mutate: mutateImport } = useMutation(
    (variables: any) => ImportExportApi.import(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-invoice-payment-list']);
          notification.success({
            message: res?.msg,
          });
          setIsOpenModalImport(false);
        }
      },
      onError(error: any) {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleImport = (value) => {
    mutateImport({
      params: {
        file: value?.file,
        item_code: ImportExportType.receiptItem,
        overwrite_all: Number(value?.overwrite),
      },
    });
  };

  const dropdownItems = [
    {
      key: 1,
      label: <a>Export file Excel</a>,
      onClick: () =>
        mutateExport({
          params: {
            item_code: ImportExportType.receiptItem,
            selected_ids: selectedRowKeys ?? [],
          },
        }),
    },
    {
      key: 2,
      label: <a>Import file excel</a>,
      onClick: () => setIsOpenModalImport(true),
    },
  ];

  return (
    <div className="tera-page">
      <HeaderViewListV2
        title="Phiếu chi tiền"
        actionRight={
          <Button
            prefix={<PlusOutlined />}
            className="bg-blue-500"
            onClick={() => navigate(`${INVOICE_PAYMENT_URL.create.path}`)}
          >
            Thêm mới
          </Button>
        }
        middleContent={<Statistic />}
        dropdownItems={dropdownItems}
        onClickFilter={() => setIsOpenModalFilter(true)}
        actionLeftRender={<Search onSearch={handleSearch} />}
        selectedNumber={selectedRowKeys?.length}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoadingDataList}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewListV2>
      {isOpenModalImport && (
        <ModalImport
          open={isOpenModalImport}
          onOk={handleImport}
          onCancel={() => {
            setIsOpenModalImport(false);
          }}
          type={ImportExportType.receiptItem}
        />
      )}
      {isOpenModalFilter && (
        <Filter
          open={isOpenModalFilter}
          onClose={() => setIsOpenModalFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default ListPage;
