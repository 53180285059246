import { IRouteProps } from '_common/interface/router';
import { CONFIG_AFFILIATES_URL } from './url';
import AffiliatesSummaryReport from '.';
import ListReferer from './containers/ListReferrer';
import ReferrerDetail from './containers/ListReferrer/detail';

export const ConfigAffiliatesRouter: IRouteProps[] = [
  {
    key: CONFIG_AFFILIATES_URL.summary_report.key,
    path: CONFIG_AFFILIATES_URL.summary_report.shortenUrl,
    component: <AffiliatesSummaryReport />,
  },
  {
    key: CONFIG_AFFILIATES_URL.referrer.key,
    path: CONFIG_AFFILIATES_URL.referrer.shortenUrl,
    component: <ListReferer />,
  },
  {
    key: CONFIG_AFFILIATES_URL.referrer_detail.key,
    path: CONFIG_AFFILIATES_URL.referrer_detail.shortenUrl,
    component: <ReferrerDetail />,
  },
];
