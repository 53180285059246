import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  getQueryParams,
  Tabs,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import { USER_STATUS, USER_STATUS_COLOR } from './constants';
import ModalChangePassword from './containers/ModalChangePassword';
import Searching from './containers/Searching';
import { USER_INFORMATION_URL } from './url';

const defaultData = [
  {
    id: 1,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 2,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'inactive',
  },
  {
    id: 3,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 4,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 5,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 6,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 7,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 8,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 9,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 10,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 11,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
  {
    id: 12,
    code: 'NV1',
    name: 'Nguyễn Văn A',
    phone: '0378858079',
    email: 'cskh@terasolutions.vn',
    status: 'active',
  },
];
const UserInformation = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [modalResetPassword, setModalResetPassword] = useState<boolean>(false);
  const confirm = useConfirm();

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(USER_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{USER_STATUS[key]}</span>
          <Badge showZero count={0} />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả đơn hàng'}</span>
            <Badge showZero count={0} />
          </h3>
        ),
      },
      ...data,
    ];
  }, []);

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ tab: key, page: 1 });
  };

  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleDelete = (id: number | string, code: string): void => {
    confirm.warning({
      title: 'Xóa người dùng',
      content: (
        <div>
          <p>Bạn có chắc muốn xóa người dùng </p>
          <p>
            <span className="break-word font-bold">{code}</span> này không?
          </p>
        </div>
      ),
      onOk: () => {
        id;
      },
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    record;
    return [
      {
        key: 1,
        label: 'Xem',
        onClick: () => {
          navigate(`${USER_INFORMATION_URL.detail.path}/${record?.id}`);
        },
      },
      {
        key: 2,
        label: 'Đổi mật khẩu',
        onClick: () => {
          setModalResetPassword(true);
        },
      },
      {
        key: 3,
        label: 'Sửa',
        onClick: () => {
          navigate(`${USER_INFORMATION_URL.update.path}/${record?.id}`);
        },
      },
      {
        key: 4,
        label: <p className="text-red-500">Xóa</p>,
        onClick: () => {
          handleDelete(record?.id, record?.code);
        },
      },
    ];
  };

  const columns: any = [
    {
      title: 'Mã Người dùng',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      width: 150,
      render: (val) => <span className="text-blue-500">{val}</span>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Email liên hệ',
      dataIndex: 'email',
      width: 150,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (val) => (
        <Tag color={USER_STATUS_COLOR[val] as any}>{USER_STATUS[val]}</Tag>
      ),
    },
    {
      title: '',
      width: 68,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown(record);
        return (
          <ActionDropdown
            dropdownItems={dropdownItems}
            trigger="click"
            containerClassName="max-h-[500px]"
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="Danh sách người dùng"
          onClickButtonAdd={() => navigate(USER_INFORMATION_URL.create.path)}
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.tab || 'all'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
          }
          actionLeftRender={<Searching onSearch={handleSearch} />}
        >
          <TableTera
            data={defaultData}
            columns={columns}
            rowKey={'id'}
            pagination={{}}
            //   pagination={{
            //     onChange: handleChangePage,
            //     total: listSaleOrder?.data?.total || 0,
            //     current: listSaleOrder?.data?.current_page,
            //     pageSize: listSaleOrder?.data?.per_page || 10,
            //     to: listSaleOrder?.data?.to,
            //     from: listSaleOrder?.data?.from || 10,
            //   }}
          />
        </HeaderViewListV2>
      </div>
      {modalResetPassword && (
        <ModalChangePassword
          userId={1}
          open={modalResetPassword}
          onClose={() => setModalResetPassword(false)}
          onSuccess={() => navigate('/auth/login')}
        />
      )}
    </>
  );
};

export default UserInformation;
