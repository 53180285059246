import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatCurrency,
  formatDate,
  notification,
} from 'tera-dls';
import InvoiceAPI from '../apis';
import {
  INVOICE_STATUS,
  PAYMENT_METHOD_TEXT,
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
  TRANSACTION_TEXT,
  TRANSACTION_TYPE,
} from '../constants';
import { INVOICE_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateApproval, isLoading: isLoadingApproval } = useMutation(
    (params: any) =>
      InvoiceAPI.approval({ id: params?.id, params: params?.params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-invoice-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleConfirmPayment = (record: any) =>
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <span className="font-bold">{record.invoice_code}</span> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateApproval({
          id: record.id,
          params: {
            status: INVOICE_STATUS.APPROVED,
          },
        }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (params: any) => InvoiceAPI.delete({ id: params?.id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-invoice-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) => {
    confirm.warning({
      title: 'Xác nhận xóa hóa đơn',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa hóa đơn</p>
          <p>
            <span className="font-bold">{record.invoice_code}</span> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateDelete({
          id: record.id,
        }),
    });
  };

  const itemActions = (item: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => {
          navigate(`${INVOICE_URL.detail.path}/${item?.id}`);
        },
      },
      ...(!_.isEqual(item.status, INVOICE_STATUS.APPROVED) &&
      _.isEqual(item.transaction_type, TRANSACTION_TYPE.PAY)
        ? [
            {
              key: '2',
              label: 'Xác nhận thanh toán',
              onClick: () => handleConfirmPayment(item),
            },
          ]
        : []),
      {
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(item),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice_code',
      width: 150,
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (transaction_type) => TRANSACTION_TEXT[transaction_type],
    },
    {
      title: 'Khách hàng',
      dataIndex: 'account_name',
      width: 150,
      render: (account_name, record) => (
        <HoverQuickView
          // avatarUrl={value?.avatar_url}
          email={record?.customer_email}
          name={account_name}
          phone={record?.customer_phone}
          className="line-clamp-2"
        >
          {account_name}
        </HoverQuickView>
      ),
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'amount',
      width: 150,
      render: (amount: number) => formatCurrency(amount),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'methods',
      width: 150,
      render: (methods) => PAYMENT_METHOD_TEXT[methods],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag className="font-medium" color={TABLE_STATUS_COLOR[status]}>
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading || isLoadingApproval || isLoadingDelete}
      className="[&_.tera-table-cell]:align-middle"
      {...props}
    />
  );
};

export default Table;
