import ImageBackground from '_common/component/ImageBackground';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { Controller } from 'react-hook-form';

const ImageForm = () => {
  const { form } = useTeraForm();
  const { item, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      {...item}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <ImageBackground
            // {...config?.field}
            value={{ url: field?.value }}
            onChange={(val) => {
              console.log(val);

              field?.onChange?.(val ? val?.url : null);
            }}
            isShowBtnDelete
            imageClassName="w-[30px] h-[30px] object-cover"
            object_key={'product-variant'}
            folder={'product-variant'}
          />
        );
      }}
    />
  );
};

export default ImageForm;
