import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ChevronDoubleRightSolid,
  Tag,
  formatCurrency,
  formatDate,
} from 'tera-dls';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../../../constants';
import { SERVICE_CLOUD_VPS_URL } from '../../../url';

interface ICurrentVPSProps {
  dataDetails: any;
}

const CurrentVPS = ({ dataDetails }: ICurrentVPSProps): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const informationDetails = [
    {
      icon: <ZingIcon.IconLocation color="#3f83f8" />,
      label: 'Vị trí:',
      value: dataDetails?.location.locationName,
    },
    {
      icon: <ZingIcon.IconSquareBox color="#3f83f8" />,
      label: 'Tên:',
      value: '',
    },
    {
      icon: <ZingIcon.IconEarth color="#3f83f8" />,
      label: 'Khu vực:',
      value: dataDetails?.countryName,
    },
    {
      icon: <ZingIcon.IconWindows color="#3f83f8" />,
      label: 'Hệ điều hành:',
      value: dataDetails?.os.osName,
    },
    {
      icon: <ZingIcon.IconCalendarCheck color="#3f83f8" />,
      label: 'Ngày tạo:',
      value: formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      icon: <ZingIcon.IconCalendarEmpty color="#3f83f8" />,
      label: 'Ngày hết hạn:',
      value: '',
    },
    {
      icon: <ZingIcon.IconCompass color="#3f83f8" />,
      label: 'Trạng thái:',
      value: (
        <Tag
          color={TABLE_STATUS_COLOR[dataDetails.status]}
          className="font-[500]"
        >
          {TABLE_STATUS_TEXT[dataDetails.status]}
        </Tag>
      ),
    },
    {
      icon: <ZingIcon.IconArrowCache color="#3f83f8" />,
      label: 'Số lần đổi IP:',
      value: <span className="text-green-500">0/15</span>,
    },
  ];

  const configDetails = [
    {
      icon: <ZingIcon.IconLocation color="#3f83f8" />,
      label: 'RAM:',
      value: dataDetails?.ram,
      unit: 'GB',
    },
    {
      icon: <ZingIcon.IconSquareBox color="#3f83f8" />,
      label: 'vCPU:',
      value: dataDetails?.cpu,
      unit: '',
    },
    {
      icon: <ZingIcon.IconEarth color="#3f83f8" />,
      label: 'SSD:',
      value: dataDetails?.ssd,
      unit: 'GB',
    },
    {
      icon: <ZingIcon.IconWindows color="#3f83f8" />,
      label: 'Mbps:',
      value: '',
      unit: '',
    },
    {
      icon: <ZingIcon.IconCalendarCheck color="#3f83f8" />,
      label: 'Wifi:',
      value: dataDetails?.bandwidth,
      unit: '',
    },
    {
      icon: <ZingIcon.IconCalendarEmpty color="#3f83f8" />,
      label: 'Đơn giá:',
      value: (
        <span className="text-red-500">
          {formatCurrency(dataDetails?.product[dataDetails?.period])}
        </span>
      ),
      unit: '/ tháng',
    },
  ];

  return (
    <div className="flex-1 bg-white p-4 rounded-[10px] drop-shadow flex flex-col">
      <div className="flex flex-col gap-4">
        <span className="text-blue-500 font-bold">
          Thông tin VPS đang sử dụng
        </span>
        <div className="px-3 flex flex-col gap-2">
          <IconText prefix={<ZingIcon.IconInformation color="#0e9f6e" />}>
            <span className="font-[500]">Thông tin chung:</span>
          </IconText>
          <div className="grid grid-cols-2">
            {informationDetails.map((detail, index) => (
              <IconText
                key={index}
                prefix={detail.icon}
                className="py-4 border-b-[1px]"
              >
                <span>{detail.label}</span>
                <span className="font-[500]">{detail.value}</span>
              </IconText>
            ))}
          </div>
        </div>
        <div className="px-3 flex flex-col gap-2">
          <IconText prefix={<ZingIcon.IconVPS color="#0E9F6E" />}>
            <span className="font-[500]">Cấu hình:</span>
          </IconText>
          <div className="grid grid-cols-2">
            {configDetails.map((detail, index) => (
              <IconText
                key={index}
                prefix={detail.icon}
                className="py-4 border-b-[1px]"
              >
                <span>{detail.label}</span>
                <span className="font-[500]">{detail.value}</span>
                <span>{detail.unit}</span>
              </IconText>
            ))}
          </div>
        </div>
        <Button
          className="w-[191px] bg-purple-500"
          prefix={<ChevronDoubleRightSolid />}
          onClick={() => navigate(`${SERVICE_CLOUD_VPS_URL.detail.path}/${id}`)}
        >
          Thông tin chi tiết
        </Button>
      </div>
    </div>
  );
};

export default CurrentVPS;
