import { useState } from 'react';
import { Tabs } from 'tera-dls';
import InvoiceDetail from './InvoiceDetail';
import Recharge from './Recharge';
import Estimate from './Estimate';

const Invoice = () => {
  const [params, setParams] = useState<any>('invoice');

  const tabItems = [
    {
      key: 'invoice',
      label: 'Hóa đơn',
    },
    {
      key: 'recharge',
      label: 'Nạp tiền',
    },
    {
      key: 'estimates',
      label: 'Estimates',
    },
  ];

  const tabData = {
    invoice: <InvoiceDetail />,
    recharge: <Recharge />,
    estimates: <Estimate />,
  };

  return (
    <div className="flex flex-col gap-[16px] pb-2.5 text-sm">
      <Tabs
        onChange={setParams}
        items={tabItems}
        activeKey={params}
        className="mb-0 pt-0 bg-white rounded-t-md"
      />
      <div className="">{tabData[params] ?? <InvoiceDetail />}</div>
    </div>
  );
};

export default Invoice;
