import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  getQueryParams,
  Spin,
  Tabs,
  updateQueryParams,
} from 'tera-dls';
import { USER_INFORMATION_URL } from '../../url';
import AccountInformation from './AccountInformation';
import Bank from './Bank';
import ChangePassword from './ChangePassword';
import History from './History';
import Invoice from './Invoice';
import Overview from './Overview';
import Security from './Security';
import SettingSecurity from './SettingSecurity';

const UserInformationDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const form = useForm({ mode: 'onChange' });

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const tabItems = [
    {
      key: 'overview',
      label: 'Tổng quan',
    },
    {
      key: 'information',
      label: 'Thông tin tài khoản',
    },
    {
      key: 'change-password',
      label: 'Thay đổi mật khẩu',
    },
    {
      key: 'security',
      label: 'Bảo mật',
    },
    {
      key: 'setting-security',
      label: 'Thiết lập bảo mật',
    },
    {
      key: 'history',
      label: 'Lịch sử',
    },
    {
      key: 'invoice',
      label: 'Hóa đơn',
    },
    {
      key: 'bank',
      label: 'Thẻ ngân hàng',
    },
  ];

  const tabData = {
    overview: <Overview />,
    information: <AccountInformation />,
    ['change-password']: <ChangePassword />,
    security: <Security />,
    ['setting-security']: <SettingSecurity />,
    history: <History />,
    invoice: <Invoice />,
    bank: <Bank />,
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ tab: key, page: 1 });
  };

  useEffect(() => {
    form.setValue('is_active', true);
  }, []);

  return (
    <Spin spinning={false}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="size-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                items={[
                  {
                    title: (
                      <a className="cursor-pointer text-blue-400 italic text-sm">
                        Danh sách người dùng
                      </a>
                    ),
                    onClick: () => navigate(USER_INFORMATION_URL.list.path),
                  },
                  {
                    title: (
                      <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px] text-sm">
                        Chi tiết người dùng
                      </a>
                    ),
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function shrink-0">
              {/* <Button onClick={form.handleSubmit(handleSubmitForm)}>Lưu</Button> */}
            </div>
          </div>
        </div>
        <div className="page-content-v2 bg-white shadow-xsm rounded-md !px-[16px] h-full mx-2.5 !mb-2.5 !gap-y-0">
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.tab || 'overview'}
            className="mb-0 pt-0 bg-white rounded-t-md"
          />
          <div className="pt-[16px]">
            {tabData[queryParams?.tab] ?? <Overview />}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default UserInformationDetail;
