import CardDetail from '_common/component/CardDetail';
import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Row, Select as SelectNormal } from 'tera-dls';

const AccountInformation = () => {
  const form = useForm({ mode: 'onChange' });
  const [selectedPhone, setSelectedPhone] = useState<string>();

  const handleSubmitForm = (values) => {
    values;
  };

  useEffect(() => {
    form.setValue('is_active', true);
  }, []);

  return (
    <div className="flex flex-col gap-[16px]">
      <CardDetail className=" text-sm">
        <p className="text-gray-500 mb-[30px]">
          Vui lòng cập nhật đúng thông tin của bạn, để tránh các vấn đề lỗi
          thông tin khi đăng ký tên miền.
        </p>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <Row className="grid-cols-1 md:grid-cols-2 xmd:grid-cols-3 gap-5">
            <FormTeraItem name="account" label="Tài khoản" className="w-full">
              <Input disabled />
            </FormTeraItem>
            <FormTeraItem
              name="email"
              label="Email"
              className="col-span-1"
              rules={[
                {
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Địa chỉ Email không hợp lệ',
                  },
                },
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input maxLength={320} placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <FormTeraItem
              name="last_name1"
              label="Danh xưng"
              className="w-full"
            >
              <Select
                options={[
                  {
                    value: 1,
                    label: 'Nam',
                  },
                  {
                    value: 2,
                    label: 'Nữ',
                  },
                ]}
              />
            </FormTeraItem>
            <FormTeraItem
              name="first_name"
              label="Họ và tên đệm"
              className="w-full"
              rules={[{ required: messageValidate.emptyText }]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              name="phone"
              label="Điện thoại"
              rules={[
                {
                  pattern: {
                    value: REGEX.PHONE_NUMBER,
                    message: 'Số điện thoại không hợp lệ',
                  },
                },
                {
                  minLength: {
                    value: 8,
                    message: 'Nhập tối thiểu 8 kí tự',
                  },
                },
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <div className="flex">
                <SelectNormal
                  placeholder=""
                  value={selectedPhone}
                  onChange={(e: any) => setSelectedPhone(e)}
                  options={[
                    {
                      value: '84',
                      label: '+84',
                      labelDisplay: (
                        <div>
                          Vietname (Việt Nam){' '}
                          <span className="text-gray-500 ">+84</span>
                        </div>
                      ),
                    },
                  ]}
                  className="rounded-r-none w-[80px] [&>*:first-child]:px-2 border-r-none"
                  dropdownClassName="!w-[250px]"
                />{' '}
                <Input maxLength={20} className="rounded-l-none" />
              </div>
            </FormTeraItem>
            <FormTeraItem name="birth_day" label="Ngày sinh">
              <div className="flex flex-col gap-1">
                <DatePicker />
                <p className="text-[10px] text-gray-400">
                  Định dạng đúng dd/mm/yyyy
                </p>
              </div>
            </FormTeraItem>

            <FormTeraItem
              name="last_name"
              label="Tên"
              className="w-full"
              rules={[{ required: messageValidate.emptyText }]}
            >
              <Input />
            </FormTeraItem>
          </Row>
        </FormTera>
      </CardDetail>
      <span>
        <Button
          onClick={form.handleSubmit(handleSubmitForm)}
          className="font-light text-[12px] bg-blue-500"
        >
          Lưu
        </Button>
      </span>
    </div>
  );
};

export default AccountInformation;
