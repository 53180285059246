import DailyTime from '_common/component/DailyTime';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import { PAYMENT_METHOD_TYPE } from 'pages/InvoiceManagement/constants';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Row } from 'tera-dls';
import { StatisticCashExpenseParamContext } from '..';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: any) => void;
  defaultValues?: any;
}

const SettingModal = (props: IProps) => {
  const { onClose, open, onSubmit, defaultValues } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { setContextParams } = useContext(StatisticCashExpenseParamContext);

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
        ...(defaultValues.accounting_date?.[0] && {
          accounting_date: [
            moment(defaultValues.accounting_date?.[0]),
            moment(defaultValues.accounting_date?.[1]),
          ],
        }),
        ...(defaultValues.date?.[0] && {
          date: [
            moment(defaultValues.date?.[0]),
            moment(defaultValues.date?.[1]),
          ],
        }),
      });
    }
  }, [defaultValues]);

  const isDirty = form?.formState?.isDirty;

  const handleSubmitForm = (value: any): void => {
    const data = {
      ...(value.location_id && { location_id: value.location_id }),
      ...(value.pay_method_type && { pay_method_type: value.pay_method_type }),
      ...(value.accounting_time && {
        accounting_time: value.accounting_time,
      }),
      ...(value.time && {
        time: value.time,
      }),

      ...(!!value.accounting_date?.[0] && {
        accounting_date: [
          value.accounting_date[0]?.format(DATE_BACKEND_FORMAT),
          value.accounting_date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
      ...(!!value.date?.[0] && {
        date: [
          value.date[0]?.format(DATE_BACKEND_FORMAT),
          value.date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
    };
    onSubmit(data);
    setContextParams(data);
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const paymentMethod = Object.entries(PAYMENT_METHOD_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <Modal
      title={'Tham số báo cáo'}
      destroyOnClose
      closeIcon={false}
      width={500}
      cancelText="Hủy"
      okText="Lưu"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <Row className="grid grid-cols-1 gap-x-5 gap-y-2.5">
          {/* <FormTeraItem
            name="location_id"
            label="Chi nhánh"
            className="col-span-1"
          >
            <SelectLocationId
              placeholder="Vui lòng chọn"
              allowClear={false}
              disabled
            />
          </FormTeraItem> */}

          <FormTeraItem
            className="col-span-1"
            label="Phương thức thanh toán"
            name="pay_method_type"
          >
            <Select
              placeholder="Vui lòng chọn"
              mode="multiple"
              options={paymentMethod}
            />
          </FormTeraItem>
          <div className="mb-2.5 bg-gray-50 border border-gray-200 col-span-1 grid grid-cols-1 gap-x-5 p-2.5 pb-0 rounded-[6px]">
            <DailyTime
              form={form}
              timeKey="accounting_time"
              timeFormProps={{
                label: 'Ngày hạch toán',
              }}
              dateKey="accounting_date"
              dateFormProps={{
                label: 'Từ ngày - đến ngày',
                rules: [{ required: 'Vui lòng chọn' }],
              }}
            />
          </div>
          <div className="mb-2.5 bg-gray-50 border border-gray-200 col-span-1 grid grid-cols-1 gap-x-5 p-2.5 pb-0 rounded-[6px]">
            <DailyTime
              form={form}
              timeKey="time"
              timeFormProps={{
                label: 'Ngày thu chi',
              }}
              dateKey="date"
              dateFormProps={{
                label: 'Từ ngày - đến ngày',
                rules: [{ required: 'Vui lòng chọn' }],
              }}
            />
          </div>
        </Row>
      </FormTera>
    </Modal>
  );
};

export default SettingModal;
