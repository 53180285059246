import { IRouteProps } from '_common/interface/router';
import UserInformation from '../UserInformation';
import UserInformationDetail from '../UserInformation/containers/Detail';
import InvoiceTemplate from '../UserInformation/containers/Detail/Invoice/InvoiceDetail/InvoiceTemplate';
import UserInformationForm from '../UserInformation/containers/Form';
import { USER_INFORMATION_URL } from './url';

export const UserInformationRouter: IRouteProps[] = [
  {
    key: USER_INFORMATION_URL.list.key,
    path: USER_INFORMATION_URL.list.shortenUrl,
    component: <UserInformation />,
  },
  {
    key: USER_INFORMATION_URL.detail.key,
    path: USER_INFORMATION_URL.detail.shortenUrl,
    component: <UserInformationDetail />,
  },
  {
    key: USER_INFORMATION_URL.create.key,
    path: USER_INFORMATION_URL.create.shortenUrl,
    component: <UserInformationForm />,
  },
  {
    key: USER_INFORMATION_URL.update.key,
    path: USER_INFORMATION_URL.update.shortenUrl,
    component: <UserInformationForm />,
  },
  {
    key: USER_INFORMATION_URL.invoiceTemplate.key,
    path: USER_INFORMATION_URL.invoiceTemplate.shortenUrl,
    component: <InvoiceTemplate />,
  },
];
