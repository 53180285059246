import { useState } from 'react';
import { Button, Input, formatCurrency } from 'tera-dls';

const PromoCode = (): JSX.Element => {
  const [isCheckingPromoCode, setIsCheckingPromoCode] =
    useState<boolean>(false);

  return (
    <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-6">
      <span className="text-blue-500">Sử dụng mã khuyến mãi</span>
      <div className="flex items-center gap-2">
        <Input className="flex-1" placeholder="Vui lòng nhập" />
        <Button
          htmlType="button"
          className="bg-blue-500"
          onClick={() => setIsCheckingPromoCode(true)}
        >
          Kiểm tra
        </Button>
      </div>
      {isCheckingPromoCode && (
        <div className="w-[360px] flex flex-col gap-2">
          <span className="text-red-500">Mã khuyến mãi không khả dụng</span>
          <div className="flex justify-between items-center">
            <span>Mã khuyến mãi</span>
            <span>KM123</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Số tiền khuyến mãi</span>
            <span className="text-green-500">{formatCurrency(100000)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromoCode;
