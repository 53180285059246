export enum SERVICE_PACKAGE_STATUS {
  Deactivate,
  ACTIVE,
  NEW,
}
export const TABS_STATUS_ITEM = ['all', '1', '0', '2'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  0: 'Đã hủy',
  1: 'Đang hoạt động',
  2: 'Đang chờ',
};
export const TABLE_STATUS_COLOR = {
  0: 'red03',
  1: 'green03',
  2: 'yellow03',
};
export const TABLE_STATUS_TEXT = {
  0: 'Đã hủy',
  1: 'Đang hoạt động',
  2: 'Đang chờ',
};
export const PRICE_PERIOD_TEXT = {
  daily: '1 ngày',
  threeDays: '3 ngày',
  weekly: '1 tuần',
  monthly: '1 tháng',
  twoMonths: '2 tháng',
  threeMonths: '3 tháng',
  sixMonths: '6 tháng',
  oneYear: '1 năm',
  twoYears: '2 năm',
  threeYears: '3 năm',
  fourYears: '4 năm',
  fiveYears: '5 năm',
};
