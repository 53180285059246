import CardDetail from '_common/component/CardDetail';
import CheckBoxGroup from '_common/dof/Control/CheckboxGroup';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Row } from 'tera-dls';

const SettingSecurity = () => {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    values;
  };

  useEffect(() => {
    form.reset({
      line: 25,
      time: 1,
      bank: 1,
      credit: 1,
      inform_email: ['2', '4'],
      merge_invoices: '1',
      nameservers: '1',
    });
  }, [form]);

  return (
    <FormTera form={form}>
      <div className="flex flex-col gap-[16px] px-2">
        <CardDetail className="pb-0">
          <p className="text-gray-500 mb-[10px]">
            Hệ thống tiếp thị liên kết toàn diện
          </p>
          <div className="flex flex-col divide-y-[2px] divide-dotted">
            <CardDetail
              className="text-sm !shadow-none pl-0"
              title="Thông tin chung"
            >
              <Row className="grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3">
                <FormTeraItem label="Số dòng hiển thị mỗi trang" name="line">
                  <Select
                    allowClear={false}
                    options={[
                      { value: 25, label: '25' },
                      { value: 50, label: '50' },
                      { value: 100, label: '100' },
                      { value: 200, label: '200' },
                    ]}
                  />
                </FormTeraItem>
                <FormTeraItem label="Múi giờ" name="time">
                  <Select
                    allowClear={false}
                    options={[
                      { value: 1, label: '(GMT + 07:00) Hanoi, TPHCM' },
                    ]}
                  />
                </FormTeraItem>
              </Row>
            </CardDetail>

            <CardDetail className="text-sm !shadow-none pl-0" title="Thông báo">
              <FormTeraItem label="Thông báo email" name="inform_email">
                <CheckBoxGroup
                  children={[
                    <Checkbox value="1" labelClassName="font-normal">
                      Thông báo cho tôi khi ai đó đăng nhập tài khoản
                    </Checkbox>,
                    <Checkbox value={'2'} labelClassName="font-normal">
                      Nhận thông báo email liên quan đến thanh toán
                    </Checkbox>,
                    <Checkbox value="3" labelClassName="font-normal">
                      Nhận thông báo email liên quan đến hỗ trợ
                    </Checkbox>,
                    <Checkbox value="4" labelClassName="font-normal">
                      Nhận thông báo email liên quan đến dịch vụ
                    </Checkbox>,
                    <Checkbox value="5" labelClassName="font-normal">
                      Nhận thông báo email liên quan đến tên miền
                    </Checkbox>,
                  ]}
                />
              </FormTeraItem>
            </CardDetail>
          </div>
        </CardDetail>
        <span>
          <Button
            onClick={form.handleSubmit(handleSubmitForm)}
            className="font-light text-[12px] bg-blue-500"
          >
            Lưu
          </Button>
        </span>
      </div>
    </FormTera>
  );
};

export default SettingSecurity;
