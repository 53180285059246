import DefaultImage from '_common/component/DefaultImage';
import { Spin, formatNumber } from 'tera-dls';
import vector_up from 'styles/images/vector_up.png';
import vector_down from 'styles/images/vector_down.png';
import classNames from 'classnames';

export interface ICardStatistic {
  title: string;
  image: string;
  amount: number;
  percentage?: number | undefined;
  type?: 'up' | 'down';
  imageClassName?: string;
  unitText?: string;
  subText?: string;
  key?: string;
  loading?: boolean;
}

const CardStatistic = (props: ICardStatistic) => {
  const {
    title = '',
    image = '',
    amount = 0,
    percentage,
    type = 'up',
    imageClassName = '',
    unitText = '',
    subText,
    loading = false,
  } = props;

  return (
    <Spin spinning={loading}>
      <div className="rounded-[5px] border-[1px] p-2.5 bg-white border-white">
        <div className="flex gap-2.5">
          <div className="flex flex-col gap-[15px] flex-1">
            <div className="line-clamp-1 text-gray-500 font-medium">
              {title}
            </div>
            <div className="flex flex-cols">
              <div>
                <span className="text-[28px] leading-7 text-green-500 font-semibold break-word">
                  {formatNumber(amount)}
                </span>{' '}
                {unitText}
              </div>
            </div>

            <div className="text-[10px] leading-[10px] flex gap-[5px] items-center">
              <DefaultImage
                src={type === 'up' ? vector_up : vector_down}
                alt={''}
                className="w-[10px] h-[5px] "
              />
              <div>
                <span
                  className={`${
                    type === 'up' ? 'text-green-500' : 'text-red-500'
                  } `}
                >
                  {percentage && `${percentage}%`}{' '}
                </span>
                <span className="text-gray-500 break-word">
                  {subText ?? 'So với tháng trước'}
                </span>
              </div>
            </div>
          </div>
          <DefaultImage
            src={image}
            alt={''}
            className={classNames(
              'w-[37px] h-[40px] object-cover',
              imageClassName,
            )}
          />
        </div>
      </div>
    </Spin>
  );
};

export default CardStatistic;
