import { useState } from 'react';
import {
  ChevronDoubleLeftSolid,
  DocumentDuplicateOutlined,
  Tooltip,
} from 'tera-dls';
import { PAYMENT_TYPE_ENUM } from '../../interfaces';
import CardRight, { CardRightProps } from './CardRight';
import customTwMerge from 'tailwind-merge.config';

const lineFirst = (
  <svg
    width="211"
    height="76"
    viewBox="0 0 211 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute top-0 -right-20"
    preserveAspectRatio="none"
  >
    <path
      opacity="0.6"
      d="M1.46833 0.958673C17.4014 36.1998 71.3055 98.2034 159.81 65.5544M159.81 65.5544C160.187 65.4152 160.565 65.2743 160.944 65.1316C250.122 31.5306 197.346 51.4131 159.81 65.5544Z"
      stroke="#D4DDEB"
      strokeWidth="2"
    ></path>
  </svg>
);

const lineSecond = (
  <svg
    width="160"
    height="146"
    viewBox="0 0 160 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute top-0 -right-20"
    preserveAspectRatio="none"
  >
    <path
      opacity="0.7"
      d="M1.54149 0.941406C-2.36707 39.419 13.1323 120.103 106.068 136.289M106.068 136.289C106.464 136.358 106.862 136.426 107.261 136.492C201.257 152.193 145.63 142.899 106.068 136.289Z"
      stroke="#D4DDEB"
      strokeWidth="2"
    ></path>
  </svg>
);

type CardBankProps = Omit<CardRightProps, 'display'> & {
  cardName: string;
  cardDesc: string;
  accountNumber: number;
  cardHolder: string;
  imageBank: string;
};
export const CardBank = ({
  cardName,
  cardDesc,
  accountNumber,
  cardHolder,
  paymentType,
  imageBank,
  ...restProps
}: CardBankProps) => {
  const [openCardRight, setOpenCardRight] = useState(false);
  return (
    <div
      className={customTwMerge(
        'relative justify-between w-full flex bg-blue-300 rounded-2xl transition-shadow',
        !openCardRight && 'shadow-smd',
      )}
    >
      <div
        className={customTwMerge(
          ' w-full z-20 transition-[width]',
          openCardRight && 'w-[50%]',
        )}
      >
        <div
          className={customTwMerge(
            'flex justify-between px-8 py-7 rounded-t-2xl relative overflow-hidden h-[144px]',
            paymentType === PAYMENT_TYPE_ENUM.BANK && 'bg-blue-800',
            paymentType === PAYMENT_TYPE_ENUM.E_WALLET && 'bg-pink-500',
          )}
        >
          <div className="text-[#D0C1E8]">
            <h3 className="font-bold text-xl">{cardName}</h3>
            <p className="h-8">{cardDesc}</p>
            <p className="flex gap-1.5">
              <p className="text-white text-xl">
                ***********{String(accountNumber).slice(-3)}
              </p>{' '}
              <Tooltip trigger="click" title="Đã copy">
                <span className="flex items-center">
                  <DocumentDuplicateOutlined
                    className="w-5 cursor-pointer text-white"
                    onClick={() =>
                      navigator.clipboard.writeText(`${accountNumber}`)
                    }
                  />
                </span>
              </Tooltip>
            </p>
          </div>
          <img
            src={imageBank}
            className="w-[46px] h-[46px] object-cover bg-cover absolute top-3 right-4"
          />
          {lineFirst}
          {!openCardRight && lineSecond}
        </div>
        <div className="px-8 py-7 text-white font-semibold text-lg bg-[#082D59] rounded-b-2xl uppercase">
          {cardHolder}
        </div>
      </div>

      {openCardRight ? (
        <CardRight
          display={openCardRight}
          paymentType={paymentType}
          {...restProps}
        />
      ) : (
        <div className="flex-1" />
      )}

      <div
        className="h-full relative z-40 right-0 flex flex-col justify-center items-center cursor-pointer w-8"
        onClick={() => setOpenCardRight(!openCardRight)}
      >
        <ChevronDoubleLeftSolid
          className={customTwMerge(
            'w-4 h-4 text-white transition-transform',
            openCardRight && 'rotate-180',
          )}
        />
      </div>
    </div>
  );
};
