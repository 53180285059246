import { useState } from 'react';
import { Tabs } from 'tera-dls';
import HistoryDetail from './HistoryDetail';
import Inform from './Inform';
import Tip from './Tip';

const History = (): JSX.Element => {
  const [params, setParams] = useState<any>({ tab: 'tip' });
  const tabItems = [
    {
      key: 'tip',
      label: 'Hoa hồng của tôi',
    },
    {
      key: 'history',
      label: 'Lịch sử email đã gửi',
    },
    {
      key: 'inform',
      label: 'Thông báo',
    },
  ];

  const data = {
    tip: <Tip />,
    history: <HistoryDetail />,
    inform: <Inform />,
  };
  return (
    <div className="flex flex-col gap-[16px] pb-2.5 text-sm">
      <Tabs
        onChange={(val) => setParams((prev = {}) => ({ ...prev, tab: val }))}
        items={tabItems}
        activeKey={params?.tab || 'tip'}
        className="mb-0 pt-0 bg-white rounded-t-md"
      />
      {data[params?.tab]}
    </div>
  );
};

export default History;
