const TimeIcon = (props) => (
  <svg
    width="21"
    height="27"
    viewBox="0 0 21 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.25 3.5013C19.9404 3.5013 20.5 2.94167 20.5 2.2513V1.41797C20.5 0.727604 19.9404 0.167969 19.25 0.167969H1.75C1.05964 0.167969 0.5 0.727604 0.5 1.41797V2.2513C0.5 2.94167 1.05964 3.5013 1.75 3.5013C1.75 8.23906 4.40708 12.2374 8.04385 13.5013C4.40708 14.7652 1.75 18.7635 1.75 23.5013C1.05964 23.5013 0.5 24.0609 0.5 24.7513V25.5846C0.5 26.275 1.05964 26.8346 1.75 26.8346H19.25C19.9404 26.8346 20.5 26.275 20.5 25.5846V24.7513C20.5 24.0609 19.9404 23.5013 19.25 23.5013C19.25 18.7635 16.5929 14.7652 12.9561 13.5013C16.5929 12.2374 19.25 8.23906 19.25 3.5013ZM10.5 11.0013C7.49026 11.0013 5.08333 7.53682 5.08333 3.5013H15.9167C15.9167 7.56094 13.4943 11.0013 10.5 11.0013Z"
      fill="#C27803"
    />
  </svg>
);
export default TimeIcon;
