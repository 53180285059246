export const INVOICE_PAYMENT_URL = {
  list: {
    key: '',
    path: '/invoice/payment/list',
    shortenUrl: 'payment/list',
  },
  create: {
    key: '',
    path: '/invoice/payment/create',
    shortenUrl: 'payment/create',
  },
  update: {
    key: '',
    path: '/invoice/payment/update',
    shortenUrl: 'payment/update/:id',
  },
};
