export const SERVICE_OPTIONS = [
  {
    value: 'domain',
    label: 'Tên miền',
  },
  {
    value: 'email',
    label: 'Email và Bảo mật',
  },
  {
    value: 'hosting',
    label: 'Web Hosting',
  },
  {
    value: 'server',
    label: 'Cloud Server',
  },
  {
    value: 'ssl',
    label: 'SSL',
  },
  {
    value: 'vps',
    label: 'Cloud VPS',
  },
];
