import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CloudVPSAPIEndpoint = `${endpoint}/admin/service`;

const CloudVPSAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${CloudVPSAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${CloudVPSAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CloudVPSAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CloudVPSAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  active: async ({ id }) =>
    await api
      .put(`${CloudVPSAPIEndpoint}/active/${id}`)
      .then((res) => res.data),
  deactivate: async ({ id }) =>
    await api
      .put(`${CloudVPSAPIEndpoint}/deactivate/${id}`)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CloudVPSAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default CloudVPSAPI;
