import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import { INVOICE_PAYMENT_URL } from './url';

export const InvoicePaymentRouter: IRouteProps[] = [
  {
    key: INVOICE_PAYMENT_URL.list.key,
    path: INVOICE_PAYMENT_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: INVOICE_PAYMENT_URL.create.key,
    path: INVOICE_PAYMENT_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: INVOICE_PAYMENT_URL.update.key,
    path: INVOICE_PAYMENT_URL.update.shortenUrl,
    component: <CreateUpdatePage />,
  },
];
