export const TABS_STATUS_ITEM = ['all', 'Active', 'New', 'Deactivate'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  Active: 'Đang hoạt động',
  New: 'Đang chờ',
  Deactivate: 'Đã hủy',
};
export const TABLE_STATUS_COLOR = {
  Active: 'green03',
  New: 'yellow03',
  Deactivate: 'red03',
};
export const TABLE_STATUS_TEXT = {
  Active: 'Đang hoạt động',
  New: 'Đang chờ',
  Deactivate: 'Đã hủy',
};
