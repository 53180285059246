import DefaultImage from '_common/component/DefaultImage';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import cost_order from 'styles/images/cost_order.png';
import number_order from 'styles/images/number_order.png';
import LightningIcon from 'styles/images/Icons/LightningIcon';
import TimeIcon from 'styles/images/Icons/TimeIcon';
import CloseIcon from 'styles/images/Icons/CloseIcon';
import WindowIcon from 'styles/images/Icons/WindowIcon';
import { formatNumber } from 'tera-dls';
import Statistic from './containers/Statistic';
const Overview = () => {
  return (
    <div className="tera-page">
      <HeaderViewListV2 title="TỔNG QUAN" />
      <div className="flex justify-center">
        <div className="w-full">
          <div className="flex flex-col gap-[30px] w-full">
            <div className="grid grid-cols-2 gap-[15px]">
              <div className="bg-white col-span-1 rounded-[5px] border-[1px] p-2.5 overflow-hidden">
                <div className="flex gap-2.5">
                  <div
                    className={'flex flex-col xmd:gap-[15px] gap-[25px] flex-1'}
                  >
                    <div className="line-clamp-1 text-gray-500 font-medium">
                      Số lượng đơn hàng
                    </div>
                    <div className="flex flex-col">
                      <span
                        className={
                          'text-base xmd:text-[28px] xmd:leading-7 flex gap-2'
                        }
                      >
                        <p className="flex gap-1.5">
                          <span className="text-[#1C64F2] text-[13px]">
                            Đã đặt
                          </span>{' '}
                          <span className="text-[22px] leading-[23px] text-[#1F2937]">
                            0
                          </span>
                        </p>
                        <p className="flex gap-1.5">
                          <span className="text-[#057A55]  text-[13px]">
                            đã hủy
                          </span>
                          <span className="text-[22px] leading-[23px] text-[#1F2937]">
                            900
                          </span>
                        </p>
                      </span>
                    </div>
                  </div>
                  <DefaultImage
                    src={number_order}
                    alt={''}
                    className={'hidden w-[40px] h-[40px] object-cover xl:block'}
                  />
                </div>
              </div>
              <div className=" bg-white col-span-1 rounded-[5px] border-[1px] p-2.5 overflow-hidden">
                <div className="flex gap-2.5">
                  <div
                    className={'flex flex-col xmd:gap-[15px] gap-[25px] flex-1'}
                  >
                    <div className="line-clamp-1 text-gray-500 font-medium">
                      Doanh thu đơn hàng
                    </div>
                    <div className="flex flex-col">
                      <span
                        className={
                          'text-base !text-[32px] leading-7 flex gap-2 text-[#0E9F6E] font-medium tracking-[1px]'
                        }
                      >
                        {formatNumber(6000000)}
                      </span>
                    </div>
                  </div>
                  <DefaultImage
                    src={cost_order}
                    alt={''}
                    className={'hidden w-[60px] h-[40px] object-cover xl:block'}
                  />
                </div>
              </div>
            </div>
            <div className="grid xmd:grid-cols-4 grid-cols-2 gap-5">
              <div className="bg-white items-center shadow-lg col-span-1 rounded-[5px] border-[1px] overflow-hidden p-[16px] flex justify-between">
                <LightningIcon className={'size-[30px]'} />
                <div className="flex flex-col gap-[5px] items-center text-[14px] leading-[17px] text-green-500 font-semibold">
                  <p>0</p>
                  <p>Đang chạy</p>
                </div>
              </div>
              <div className="bg-white items-center shadow-lg col-span-1 rounded-[5px] border-[1px] overflow-hidden p-[16px] flex justify-between">
                <TimeIcon className={'size-[30px]'} />
                <div className="flex flex-col gap-[5px] items-center text-[14px] leading-[17px] text-yellow-500 font-semibold">
                  <p>0</p>
                  <p>Sắp hết hạn</p>
                </div>
              </div>
              <div className="bg-white items-center shadow-lg col-span-1 rounded-[5px] border-[1px] overflow-hidden p-[16px] flex justify-between">
                <CloseIcon className={'size-[28px]'} />
                <div className="flex flex-col gap-[5px] items-center text-[14px] leading-[17px] text-gray-500 font-semibold">
                  <p>0</p>
                  <p>Đã hủy</p>
                </div>
              </div>
              <div className="bg-white items-center shadow-lg col-span-1 rounded-[5px] border-[1px] overflow-hidden p-[16px] flex justify-between">
                <WindowIcon className={'size-[28px]'} />
                <div className="flex flex-col gap-[5px] items-center text-[14px] leading-[17px] text-blue-500 font-semibold">
                  <p>0</p>
                  <p>Tổng số</p>
                </div>
              </div>
            </div>
            <div>
              <Statistic />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
