import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, Tabs, updateQueryParams } from 'tera-dls';
import Overview from './containers/Overview';
import AccountInformation from './containers/AccountInformation';
import ChangePassword from '../UserInformation/containers/Detail/ChangePassword';
import SettingSecurity from './containers/SettingSecurity';
import History from './../UserInformation/containers/Detail/History';

const MyInformation = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const form = useForm({ mode: 'onChange' });

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const tabItems = [
    {
      key: 'overview',
      label: 'Tổng quan',
    },
    {
      key: 'information',
      label: 'Thông tin tài khoản',
    },
    {
      key: 'change-password',
      label: 'Thay đổi mật khẩu',
    },

    {
      key: 'setting-security',
      label: 'Thiết lập bảo mật',
    },
    {
      key: 'history',
      label: 'Lịch sử',
    },
  ];

  const tabData = {
    overview: <Overview />,
    information: <AccountInformation />,
    ['change-password']: <ChangePassword />,
    ['setting-security']: <SettingSecurity />,
    history: <History />,
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ tab: key, page: 1 });
  };

  useEffect(() => {
    form.setValue('is_active', true);
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewListV2 title="THÔNG TIN CỦA TÔI">
        <div className="page-content-v2 !px-[16px] h-full !mb-2.5 !gap-y-0">
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.tab || 'overview'}
            className="mb-0 pt-0 bg-white rounded-t-md"
          />
          <div className="pt-[16px]">
            {tabData[queryParams?.tab] ?? <Overview />}
          </div>
        </div>
      </HeaderViewListV2>
    </div>
  );
};

export default MyInformation;
