import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { useState } from 'react';
import { Button, DropdownItem, FunnelOutlined, PlusOutlined } from 'tera-dls';
import BankFilter from './Filter';
import Searching from './Searching';
import BankFormData from './Form';
import useConfirm from '_common/hooks/useConfirm';
import BankDetail from './Detail';

const Bank = () => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [params, setParams] = useState<any>();
  const [openForm, setOpenForm] = useState<any>({ open: false });
  const [openDetail, setOpenDetail] = useState<any>({ open: false });
  const confirm = useConfirm();

  const handleDelete = (id: number, name: string) => {
    confirm.warning({
      title: 'Xác nhận xóa ngân hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc chắn muốn xóa ngân hàng</p>
          <p>
            <span className="font-bold">{name}</span> này không?
          </p>
        </div>
      ),
      onOk: () => {
        id;
      },
    });
  };

  const generateDropDownItems = (record: any): DropdownItem[] => {
    record;
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => {
          setOpenDetail({ open: true });
        },
      },
      {
        key: 2,
        label: <a>Sửa</a>,
        onClick: () => {
          setOpenForm({ open: true, value: record?.id });
        },
      },
      {
        key: 3,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => {
          handleDelete(record?.id, 'dsjdnsd');
        },
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Tên thẻ/ngân hàng',
      dataIndex: 'name',
      width: 200,
      render: (val) => <span className="text-blue-500">{val}</span>,
    },
    {
      title: 'Tên đầy đủ',
      dataIndex: 'full_name',
      width: 200,
    },
    {
      title: 'Người thụ hưởng',
      dataIndex: 'receiver',
      width: 150,
    },
    {
      title: 'STK/Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },

    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const handleSearch = ({ keyword }) => {
    setParams((prev) => ({ ...prev, keyword, page: 1 }));
  };

  return (
    <div>
      <div className="flex justify-end mb-2.5">
        <Button
          onClick={() => {
            setOpenForm({ open: true });
          }}
          className="px-[12px] py-[5px] rounded-[4px] flex gap-2.5 bg-blue-500"
        >
          <div className="flex gap-[5px] items-center">
            <PlusOutlined className="w-[17px] h-[17px]" />
            <span>Thêm mới</span>
          </div>
        </Button>
      </div>
      <div className="flex justify-end flex-col [box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] rounded-md py-2 gap-2">
        <div className="flex flex-col px-2 justify-end items-end gap-2">
          <div className="flex gap-2.5">
            <Searching onSearch={handleSearch} />
            <Button
              type="alternative"
              className="rounded-xsm py-1 px-2  bg-blue-50"
              onClick={() => setOpenFilter(true)}
            >
              <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0 size-4" />
            </Button>
          </div>
        </div>
        <TableTera
          columns={columns}
          className="center-table"
          wrapperClassName="shadow-none"
          pagination={{}}
          data={[
            {
              id: 1,
              name: 'Momo',
              full_name: 'Công ty TNHH TM DV XNK MOMO',
              receiver: 'Nguyễn Thị Hà Thư',
              phone: '0909123456',
            },
            {
              id: 2,
              name: 'Momo',
              full_name: 'Công ty TNHH TM DV XNK MOMO',
              receiver: 'Nguyễn Thị Hà Thư',
              phone: '0909123456',
            },
          ]}
        />
      </div>
      {openFilter && (
        <BankFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValue={{ ...params }}
          onFilter={(val) =>
            setParams((prev = {}) => ({ ...prev, ...val, page: 1 }))
          }
        />
      )}
      {openForm?.open && (
        <BankFormData
          open={openForm?.open}
          onCancel={() => setOpenForm({ open: false })}
          value={openForm?.value}
        />
      )}
      {openDetail?.open && (
        <BankDetail
          open={openDetail?.open}
          onCancel={() => setOpenDetail({ open: false })}
          value={openDetail?.value}
        />
      )}
    </div>
  );
};

export default Bank;
