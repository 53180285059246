import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvoiceAPIEndpoint = `${endpoint}/admin/finance/invoice`;

const InvoiceAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${InvoiceAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvoiceAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  approval: async ({ id, params }) =>
    await api
      .post(`${InvoiceAPIEndpoint}/approval/${id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${InvoiceAPIEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default InvoiceAPI;
