import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import { useState } from 'react';
import { Row, Select as SelectNormal } from 'tera-dls';

const UserFormData = ({ isForm = true }) => {
  const [selectedPhone, setSelectedPhone] = useState<string>();

  return (
    <Row className="grid-cols-1 md:grid-cols-2 xmd:grid-cols-3 gap-5">
      <FormTeraItem
        name="account"
        label="Tài khoản"
        className="w-full"
        rules={[{ required: messageValidate.emptyText }]}
      >
        <Input />
      </FormTeraItem>
      <FormTeraItem
        name="email"
        label="Email"
        className="col-span-1"
        rules={[
          {
            pattern: {
              value: REGEX.EMAIL,
              message: 'Địa chỉ Email không hợp lệ',
            },
          },
          {
            required: messageValidate.emptyText,
          },
        ]}
      >
        <Input maxLength={320} placeholder="Vui lòng nhập" />
      </FormTeraItem>
      <FormTeraItem
        name="country"
        label="Quốc gia"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <Select options={[]} />
      </FormTeraItem>
      <FormTeraItem
        name="first_name"
        label="Họ và tên đệm"
        className="w-full"
        rules={[{ required: messageValidate.emptyText }]}
      >
        <Input />
      </FormTeraItem>
      <FormTeraItem name="birth_day" label="Ngày sinh">
        <div className="flex flex-col gap-1">
          <DatePicker />
          <p className="text-[10px] text-gray-400">Định dạng đúng dd/mm/yyyy</p>
        </div>
      </FormTeraItem>
      <FormTeraItem
        name="city"
        label="Tỉnh/Thành"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <Select options={[]} />
      </FormTeraItem>
      <FormTeraItem
        name="last_name"
        label="Tên"
        className="w-full"
        rules={[{ required: messageValidate.emptyText }]}
      >
        <Input />
      </FormTeraItem>
      <FormTeraItem
        name="phone"
        label="Điện thoại"
        rules={[
          {
            pattern: {
              value: REGEX.PHONE_NUMBER,
              message: 'Số điện thoại không hợp lệ',
            },
          },
          {
            minLength: {
              value: 8,
              message: 'Nhập tối thiểu 8 kí tự',
            },
          },
          {
            required: messageValidate.emptyText,
          },
        ]}
      >
        <div className="flex">
          <SelectNormal
            placeholder=""
            value={selectedPhone}
            onChange={(e: any) => setSelectedPhone(e)}
            placement="bottom-start"
            options={[
              {
                value: '84',
                label: '+84',
                labelDisplay: (
                  <div>
                    Vietname (Việt Nam){' '}
                    <span className="text-gray-500 ">+84</span>
                  </div>
                ),
              },
            ]}
            className="rounded-r-none w-[80px] [&>*:first-child]:px-2 [&>*:first-child]:bg-gray-100"
            dropdownClassName="!w-[250px]"
          />
          <Input maxLength={20} className="rounded-l-none" />
        </div>
      </FormTeraItem>
      <FormTeraItem
        name="province"
        label="Quận/huyện"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <Select options={[]} />
      </FormTeraItem>
      <FormTeraItem name="last_name1" label="Danh xưng" className="w-full">
        <Select
          options={[
            {
              value: 1,
              label: 'Nam',
            },
            {
              value: 2,
              label: 'Nữ',
            },
          ]}
        />
      </FormTeraItem>
      <FormTeraItem
        name="address"
        label="Địa chỉ"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <Input />
      </FormTeraItem>
      <FormTeraItem
        name="ward"
        label="Phường/Xã"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <Select options={[]} />
      </FormTeraItem>
      <FormTeraItem
        name="passport"
        label="Số CCCD/Passport"
        className="w-full"
        rules={[{ required: messageValidate.emptyText }]}
      >
        <Input />
      </FormTeraItem>
      {isForm ? (
        <>
          <FormTeraItem
            label="Mật khẩu"
            name="new_password"
            rules={[
              {
                required: messageValidate.emptyText,
                pattern: {
                  value: new RegExp(REGEX.PASSWORD),
                  message: messageValidate.password,
                },
                minLength: {
                  value: 8,
                  message: messageValidate.min_length_password,
                },
                maxLength: {
                  value: 16,
                  message: messageValidate.max_length_password,
                },
              },
            ]}
          >
            <InputPassword />
          </FormTeraItem>
          <FormTeraItem
            label="Nhập lại mật khẩu"
            name="confirm_password"
            rules={[
              {
                required: messageValidate.emptyText,
                pattern: {
                  value: new RegExp(REGEX.PASSWORD),
                  message: messageValidate.password,
                },
                minLength: {
                  value: 8,
                  message: messageValidate.min_length_password,
                },
                maxLength: {
                  value: 16,
                  message: messageValidate.max_length_password,
                },
                validate: {
                  existed: (value, values) => {
                    const password = values?.new_password.trim();

                    if (value?.trim() !== password) {
                      return messageValidate.confirm_password;
                    }
                  },
                },
              },
            ]}
          >
            <InputPassword />
          </FormTeraItem>
        </>
      ) : (
        <>
          <div className="hidden xmd:block" />
          <div className="hidden xmd:block" />
        </>
      )}

      <FormTeraItem name="is_active" label="" className="w-full">
        <CheckBox labelClassName="font-normal text-[13px]">Hoạt động</CheckBox>
      </FormTeraItem>
    </Row>
  );
};

export default UserFormData;
