import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, DropdownItem, Tag, formatCurrency } from 'tera-dls';
import { DOMAIN_STATUS, DOMAIN_STATUS_COLOR } from '../constants';
import { SERVICE_CLOUD_SERVER_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const navigate = useNavigate();

  const itemActions = (): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${SERVICE_CLOUD_SERVER_URL.detail.path}/1`),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: '1',
      render: () => <span className="text-blue-500">321324</span>,
    },
    {
      title: 'Dịch vụ',
      dataIndex: '2',
      render: () => (
        <div className="flex flex-col gap-1">
          <span className="text-gray-500">COMODO SSL</span>
          <span className="text-blue-500">Comodo PositiveSSL</span>
        </div>
      ),
    },
    {
      title: 'Giá',
      dataIndex: '3',
      render: () => formatCurrency(2900000),
    },
    {
      title: 'Chu kỳ thanh toán',
      dataIndex: '4',
      render: () => 'Hàng tháng',
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: '5',
      render: () => 'dd/mm/yyyy',
    },
    {
      title: 'Người dùng',
      dataIndex: 'username',
      render: () => <span className="text-blue-500">Nguyễn Văn A</span>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <Tag
          color={
            !props.queryParams.status || props.queryParams.status === 'all'
              ? status !== undefined
                ? DOMAIN_STATUS_COLOR[status]
                : DOMAIN_STATUS_COLOR[1]
              : DOMAIN_STATUS_COLOR[props.queryParams.status]
          }
          className="font-[500]"
        >
          {!props.queryParams.status || props.queryParams.status === 'all'
            ? status !== undefined
              ? DOMAIN_STATUS[status]
              : DOMAIN_STATUS[1]
            : DOMAIN_STATUS[props.queryParams.status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: () => {
        return <ActionDropdown dropdownItems={itemActions()} trigger="click" />;
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading}
        className="[&_.tera-table-expanded-row-fixed]:px-0 [&_.tera-table-cell]:align-middle"
        {...props}
      />
    </Fragment>
  );
};

export default Table;
