import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_TIME_BACKEND_FORMAT,
  DATE_TIME_FORMAT,
} from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import UploadFiles from '_common/dof/UploadFiles';
import _ from 'lodash';
import moment from 'moment';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Row, notification } from 'tera-dls';
import InvoiceReceiptAPI from '../../apis';
import ExplainTable, { IExplainTableRef } from '../../components/ExplainTable';
import { PAYMENT_METHOD_TEXT, RECEIPT_TYPE_TEXT } from '../../constants';

export interface ICashReceiptFormContentRef {
  submit: () => void;
  getIsDirty: () => boolean;
  checkError: () => boolean;
}

interface IProps {
  onSuccess?: () => void;
  id?: number | string;
}

const CashReceiptFormContent = (props, ref) => {
  const { onSuccess, id } = props;
  const cashExplainRef = useRef<IExplainTableRef>(null);
  const form = useForm({ mode: 'onChange' });
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<any>([]);
  const [explains, setExplains] = useState<any>();

  const { data: detail, refetch } = useQuery(
    ['get-cash-receipt-detail', id],
    () => InvoiceReceiptAPI.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (!detail) return;
    const data = _.pick(detail, [
      'code',
      'methods',
      'author',
      'transaction_type',
      'note',
      'is_check',
    ]);

    detail.file_upload &&
      setFiles(
        detail.file_upload?.map((item) => ({
          id: item.id,
          name: item.name,
          url: item.url,
        })),
      );

    form.reset({
      ...data,
      ...(detail.date && { date: moment(detail.date) }),
      ...(detail.accounting_date && {
        accounting_date: moment(detail.accounting_date),
      }),
    });
  }, [detail]);

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      id
        ? InvoiceReceiptAPI.update({ ...variables, id })
        : InvoiceReceiptAPI.create(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmit = (values: any): void => {
    mutateAction({
      params: {
        ...values,
        date: values?.date
          ? moment(values?.date).format(DATE_TIME_BACKEND_FORMAT)
          : moment().format(DATE_TIME_BACKEND_FORMAT),
        accounting_date: values?.accounting_date
          ? moment(values?.accounting_date).format(DATE_TIME_BACKEND_FORMAT)
          : moment().format(DATE_TIME_BACKEND_FORMAT),
        ...(files && { file_upload: files }),
        ...(explains && !id && { accounting: explains }),
        type: 'payment',
      },
    });
  };

  useEffect(() => {
    if (!id) {
      form.setValue('is_check', true, { shouldDirty: false });
      form.setValue('methods', 'transfer', { shouldDirty: false });
      form.setValue('accounting_date', moment(), { shouldDirty: false });
      form.setValue('date', moment(), { shouldDirty: false });
    } else {
      refetch();
    }
  }, [form, id]);

  useImperativeHandle(
    ref,
    () => ({
      checkError() {
        return cashExplainRef?.current?.checkError();
      },
      async submit() {
        form.handleSubmit(handleSubmit)();
      },
      async getIsDirty() {
        const isNotErrors = await cashExplainRef?.current?.checkError();
        if (detail) {
          return (
            form.formState.isDirty ||
            JSON.stringify(files) !==
              JSON.stringify(detail.file_upload || []) ||
            // JSON.stringify(explains) !== JSON.stringify(detail?.explain) ||
            !isNotErrors
          );
        }

        return (
          form.formState.isDirty || files?.length > 0 || explains?.length > 0
          // !isNotErrors
        );
      },
    }),
    [
      form,
      form.formState.isDirty,
      handleSubmit,
      cashExplainRef,
      detail,
      explains,
    ],
  );

  const paymentMethod = Object.entries(PAYMENT_METHOD_TEXT).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const typeOptions = Object.entries(RECEIPT_TYPE_TEXT).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <FormTera form={form} onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 p-[16px] gap-[16px] bg-white rounded">
        <div className="col-span-1 grid gap-5">
          <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
            Thông tin chung
          </div>

          <Row className="grid grid-cols-2 gap-y-0">
            <FormTeraItem
              className="col-span-1"
              label="Phương thức thanh toán"
              name="methods"
            >
              <Select placeholder="Vui lòng chọn" options={paymentMethod} />
            </FormTeraItem>
            <FormTeraItem
              className="col-span-1"
              label="Loại phiếu chi"
              name="transaction_type"
              rules={[{ required: messageValidate.emptySelect }]}
            >
              <Select
                placeholder="Vui lòng chọn"
                options={typeOptions}
                allowClear
              />
            </FormTeraItem>
            <FormTeraItem
              label="Người nộp"
              name="author"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem label="Ngày tạo phiếu chi" name="date">
              <DatePicker
                placeholder="Vui lòng chọn"
                allowClear
                showTime
                format={DATE_TIME_FORMAT}
              />
            </FormTeraItem>
            <FormTeraItem label="Ngày hạch toán" name="accounting_date">
              <DatePicker
                placeholder="Vui lòng chọn"
                allowClear
                showTime
                format={DATE_TIME_FORMAT}
              />
            </FormTeraItem>
          </Row>
          <Row>
            <FormTeraItem label="" name="is_check">
              <CheckBox labelClassName="font-normal text-[13px] leading-[16px]">
                Hạch toán vào kết quả kinh doanh
              </CheckBox>
            </FormTeraItem>
          </Row>
          <Row>
            <FormTeraItem
              label="Ghi chú"
              name="note"
              rules={[
                {
                  maxLength: {
                    value: 1000,
                    message: messageValidateLength.textArea,
                  },
                },
              ]}
            >
              <TextArea placeholder="Vui lòng nhập" rows={5} />
            </FormTeraItem>
          </Row>
          <Row>
            <UploadFiles
              object_key="cash-receipt"
              folder="cash-receipt"
              fileList={files}
              onReceiveFiles={(_, files) => {
                setFiles(files);
              }}
              onRemove={(file) => {
                setFiles((prev) => prev.filter((item) => item.id !== file.id));
              }}
              className="max-w-max"
              max={10}
              maxSize={10}
            />
          </Row>
        </div>
        <div className="col-span-1 flex flex-col gap-5">
          <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
            Danh sách hạch toán
          </div>
          <ExplainTable
            type="cash-receipt"
            mode={id ? 'default' : 'soft'}
            id={id}
            objectType="cash-explain"
            onChange={setExplains}
            ref={cashExplainRef}
            onSuccess={() => {
              queryClient.invalidateQueries(['get-cash-receipt-list']);
              queryClient.invalidateQueries(['get-cash-receipt-statistic']);
            }}
          />
        </div>
      </div>
    </FormTera>
  );
};

export default forwardRef<ICashReceiptFormContentRef, IProps>(
  CashReceiptFormContent,
);
