import CopyClipboardText from '_common/component/CopyClipboardText';
import { timeFormat } from '_common/constants/common';
import {
  PAYMENT_METHOD_TEXT,
  TRANSACTION_TEXT,
} from 'pages/InvoiceManagement/Invoice/constants';
import { ReactComponent as IconTransactionAmount } from 'styles/images/Icons/transaction-amount.svg';
import {
  ClipboardDocumentListOutlined,
  Description,
  LifebuoySolid,
  Modal,
  Spin,
  formatCurrency,
  formatDate,
} from 'tera-dls';
import { statusTransactionRender } from '../../constants';

const ModalConfirmPayment = ({ isLoading, data, open, onClose }) => {
  const card = data?.card;

  const generalFirst = [
    {
      label: 'Họ và tên',
      value: data?.bill?.bill?.customer_name,
    },
    {
      label: 'Trạng thái',
      value: statusTransactionRender[data?.status],
    },
    {
      label: 'Thời gian',
      value: formatDate(data?.transaction_date, timeFormat.date_time),
    },
  ];
  const generalSecond = [
    {
      label: 'Mã giao dịch',
      value: (
        <p className="flex items-center justify-end gap-2.5">
          {data?.transaction_code}
          <CopyClipboardText valueCopy={data?.transaction_code}>
            <ClipboardDocumentListOutlined />
          </CopyClipboardText>
        </p>
      ),
    },
    {
      label: 'Mã hóa đơn',
      value: data?.bill?.bill?.code && (
        <p className="flex items-center justify-end gap-2.5">
          {data?.bill?.bill?.code}
          <CopyClipboardText valueCopy={data?.bill?.bill?.code}>
            <ClipboardDocumentListOutlined />
          </CopyClipboardText>
        </p>
      ),
    },
    {
      label: 'Phương thức thanh toán',
      value: PAYMENT_METHOD_TEXT[data?.methods],
    },
  ];

  const detail = [
    ...(card?.account_number || card?.account_phone
      ? [
          {
            label: 'Số tài khoản',
            value: card?.account_number || card?.account_phone,
          },
        ]
      : []),
    ...(card?.cardholder
      ? [
          {
            label: 'Chủ tài khoản',
            value: card?.cardholder,
          },
        ]
      : []),
    ...(card?.card_type?.card_name
      ? [
          {
            label: 'Ngân hàng',
            value: card?.card_type?.card_name,
          },
        ]
      : []),

    // {
    //   label: 'IBAN',
    //   value: '423545243545',
    // },
    // {
    //   label: 'BIC',
    //   value: (
    //     <p className="flex items-center gap-2.5">
    //       42354523432
    //       <CopyClipboardText valueCopy="42354523432" />
    //     </p>
    //   ),
    // },
  ];
  return (
    <Modal
      width={'25%'}
      open={open}
      title="Thanh toán thành công"
      footerClassName="!border-none !justify-center"
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={onClose}
    >
      <Spin spinning={isLoading}>
        <div className="shadow-[0_4px_4px_#0000001A,0_-4px_4px_#00000008] p-4 rounded-md bg-[url('styles/images/payment-method/bg-confirm-payment.png')]">
          <div className="flex gap-2.5 items-center">
            <i className="w-5 h-5 fill-[#E05252]">
              <IconTransactionAmount />
            </i>
            <div className="text-base space-y-1">
              <p className="font-light">
                {TRANSACTION_TEXT[data?.transaction_type]}
              </p>
              <p className="font-bold">
                {data?.transaction_type === 1 ? '+' : '-'}{' '}
                {formatCurrency(data?.amount)}
              </p>
            </div>
          </div>

          <div className="divide-y divide-dashed">
            <div className="py-4 space-y-2">
              {generalFirst.map((item) => (
                <Description
                  label={item.label}
                  childrenClassName="text-right"
                  className="mb-0 items-center"
                >
                  {item.value}
                </Description>
              ))}
            </div>

            <div className="py-4 space-y-2">
              {generalSecond.map((item) => (
                <Description
                  label={item.label}
                  childrenClassName="text-right"
                  className="mb-0"
                >
                  {item.value}
                </Description>
              ))}
            </div>

            {detail?.length > 0 && (
              <div className="py-4 space-y-2">
                <div className="border border-gray-400 rounded-[10px] p-4 space-y-2.5">
                  {detail.map((item) => (
                    <Description
                      label={item.label}
                      labelClassName="text-right"
                      className="mb-0 gap-6"
                    >
                      {item.value}
                    </Description>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-center gap-2.5 py-2 bg-blue-100 -mx-4 -mb-4 text-blue-500">
            <LifebuoySolid className="w-6 h-6" />
            Liên hệ hỗ trợ
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalConfirmPayment;
