import { useMutation, useQuery } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { ImportExportType } from '_common/component/ImportExport/constants';
import { download } from '_common/component/ImportExport/util';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ImportExportApi from 'states/api/ImportExportApi';
import {
  PaginationProps,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';
import InvoiceAPI from './apis';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { INVOICE_URL } from './url';

const ListPage = () => {
  const [isOpenAsideFilter, setIsOpenAsideFilter] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVOICE_URL.list.path}${paramString}`);
  };

  const handleSearch = ({ keyword }): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const {
    data: dataList,
    isLoading: isLoadingDataList,
    refetch: refetchDataList,
  } = useQuery(
    ['get-invoice-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return InvoiceAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const { mutate: mutateExport } = useMutation(
    (variables: any) => ImportExportApi.export(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          setSelectedRowKeys([]);
          download(res?.data?.src, ImportExportType.receiptItem);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const dropdownItems = [
    {
      key: 1,
      label: <a>Export file Excel</a>,
      onClick: () =>
        mutateExport({
          params: {
            item_code: ImportExportType.receiptItem,
            selected_ids: selectedRowKeys ?? [],
          },
        }),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH HÓA ĐƠN"
          dropdownItems={dropdownItems}
          onClickFilter={() => setIsOpenAsideFilter(true)}
          actionLeftRender={<Search onSearch={handleSearch} />}
          selectedNumber={selectedRowKeys.length}
        >
          <Table
            rowKey={'id'}
            data={dataList?.data || []}
            loading={isLoadingDataList}
            rowSelection={{
              selectedRowKeys,
              onChange: setSelectedRowKeys,
            }}
            pagination={{
              onChange: handleChangePage,
              total: dataList?.total || 0,
              current: dataList?.current_page,
              pageSize: dataList?.per_page || 10,
              to: dataList?.to,
              from: dataList?.from || 10,
            }}
          />
        </HeaderViewListV2>
      </div>
      {isOpenAsideFilter && (
        <Filter
          open={isOpenAsideFilter}
          onClose={() => setIsOpenAsideFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default ListPage;
