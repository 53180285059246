export const USER_STATUS = {
  active: 'Đang hoạt đông',
  inactive: 'Ngừng hoạt động',
};

export const USER_STATUS_COLOR = {
  active: 'green03',
  inActive: 'gray03',
};

export const TransactionMethod = {
  1: 'Chưa xác minh',
  2: 'Số dư',
  3: 'Vietcombank',
};

export const TransactionType = {
  1: 'Nạp tiền',
  2: 'Rút tiền',
  3: 'Tạo mới',
  4: 'Gia hạn',
};
