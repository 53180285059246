const UserIcon = (props) => (
  <svg
    width="21"
    height="25"
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4058 0.937533C13.7405 1.12414 13.9048 1.51495 13.804 1.88466L11.591 9.99871H19.6667C19.9985 9.99871 20.2987 10.1956 20.4309 10.4999C20.5632 10.8043 20.5023 11.1581 20.2759 11.4006L8.60922 23.9006C8.34775 24.1808 7.92892 24.2465 7.59422 24.0599C7.25951 23.8733 7.09521 23.4825 7.19604 23.1128L9.40896 14.9987H1.33335C1.00152 14.9987 0.701313 14.8018 0.569062 14.4975C0.436812 14.1932 0.497725 13.8394 0.724139 13.5968L12.3908 1.09679C12.6523 0.816647 13.0711 0.75093 13.4058 0.937533Z"
      fill="#0E9F6E"
    />
  </svg>
);
export default UserIcon;
