import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  Description,
  DocumentDuplicateOutlined,
  formatCurrency,
  Modal,
  Tooltip,
} from 'tera-dls';

interface IProps {
  open: boolean;
  value?: number;
  onCancel: () => void;
}

const lineFirst = (
  <svg
    width="211"
    height="76"
    viewBox="0 0 211 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute top-0 -right-20"
    preserveAspectRatio="none"
  >
    <path
      opacity="0.6"
      d="M1.46833 0.958673C17.4014 36.1998 71.3055 98.2034 159.81 65.5544M159.81 65.5544C160.187 65.4152 160.565 65.2743 160.944 65.1316C250.122 31.5306 197.346 51.4131 159.81 65.5544Z"
      stroke="#D4DDEB"
      strokeWidth="2"
    ></path>
  </svg>
);

const BankDetail = (props: IProps) => {
  const { open, value: id, onCancel } = props;
  id;
  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết thẻ'}
        closeIcon={false}
        width={500}
        footer={
          <div className="flex justify-center">
            <Button onClick={onCancel}>Đóng</Button>
          </div>
        }
      >
        <div
          className={customTwMerge('w-full z-20 transition-[width] my-3 mb-7')}
        >
          <div
            className={customTwMerge(
              'flex justify-between px-8 py-7 rounded-t-2xl relative overflow-hidden h-[144px] bg-blue-800',
            )}
          >
            <div className="text-[#D0C1E8]">
              <h3 className="font-bold text-xl">Momo</h3>
              <p className="h-8">Công ty TNHH TM DV XNK MOMO</p>
              <p className="flex gap-1.5">
                <p className="text-white text-xl">1232323232</p>{' '}
                <Tooltip trigger="click" title="Đã copy">
                  <span className="flex items-center">
                    <DocumentDuplicateOutlined
                      className="w-5 cursor-pointer text-white"
                      onClick={() =>
                        navigator.clipboard.writeText(`${123123232}`)
                      }
                    />
                  </span>
                </Tooltip>
              </p>
            </div>
            <img
              src={''}
              className="w-[46px] h-[46px] object-cover bg-cover absolute top-3 right-4"
            />
            {lineFirst}
          </div>
          <div className="px-8 py-7 text-white font-semibold text-lg bg-[#082D59] rounded-b-2xl uppercase">
            Nguyễn Thị Hà Thư
          </div>
        </div>
        <Description label="Tổng thu">{formatCurrency(29000000)}</Description>
        <Description label="Số lần giao dịch">99</Description>
      </Modal>
    </>
  );
};

export default BankDetail;
