import { useMutation, useQueryClient } from '@tanstack/react-query';
import ZingIcon from '_common/component/ZingIcon';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useState } from 'react';
import FlagVN from 'styles/images/Icons/FlagVN.svg';
import { Button, ColumnsType, Tag, formatDate, notification } from 'tera-dls';
import CloudVPSAPI from '../apis';
import {
  CLOUD_VPS_STATUS,
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from '../constants';
import TableRowExpand from './TableRowExpand';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } =
    useMutation(
      (params: any) =>
        _.isEqual(params.status, CLOUD_VPS_STATUS.RUNNING)
          ? CloudVPSAPI.deactivate({ id: params.id })
          : CloudVPSAPI.active({ id: params.id }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-service-cloud-vps-list']);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleUpdateStatus = (record) =>
    confirm.warning({
      title: `Xác nhận ${
        _.isEqual(record.active, CLOUD_VPS_STATUS.RUNNING) ? 'hủy' : ''
      } kích hoạt dịch vụ`,
      content: (
        <div className="flex flex-col">
          <p>
            {`Bạn có chắc muốn ${
              _.isEqual(record.active, CLOUD_VPS_STATUS.RUNNING) ? 'hủy' : ''
            } kích hoạt dịch vụ`}
          </p>
          <p>
            <b>{record?.planCode}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateUpdateStatus({ id: record.id, status: record.status }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => CloudVPSAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-service-cloud-vps-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = (record) =>
    confirm.warning({
      title: 'Xác nhận xóa dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa dịch vụ</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const columns: ColumnsType<any> = [
    {
      title: 'IP',
      dataIndex: 'ip',
      width: 140,
      render: (ip) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconLocation className="w-4 h-4" color="#3f83f8" />
          {ip}
        </div>
      ),
    },
    {
      title: 'Vị trí',
      dataIndex: 'location',
      width: 150,
      render: (location) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconSquareBox className="w-4 h-4" color="#3f83f8" />
          {location?.locationName}
        </div>
      ),
    },
    {
      title: 'Tên',
      dataIndex: 'planCode',
      width: 100,
      render: (planCode) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconEarth className="w-4 h-4" color="#3f83f8" />
          {planCode}
          <img
            src={FlagVN}
            alt="name_flag"
            className="w-4 h-4 rounded-[1rem] object-cover"
          />
        </div>
      ),
    },
    {
      title: 'OS',
      dataIndex: 'os',
      width: 175,
      render: (os) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconWindows className="w-4 h-4" color="#3f83f8" />
          {os?.osName}
        </div>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 175,
      render: (created_at) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCalendarCheck className="w-4 h-4" color="#3f83f8" />
          {formatDate(created_at, DATE_TIME_FORMAT)}
        </div>
      ),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expirated_at',
      width: 175,
      render: (expirated_at) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCalendarEmpty className="w-4 h-4" color="#3f83f8" />
          {expirated_at}
        </div>
      ),
    },
    {
      title: 'Người dùng',
      dataIndex: 'username',
      width: 150,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCompass className="w-4 h-4" color="#3f83f8" />
          <Tag color={TABLE_STATUS_COLOR[status]} className="font-[500]">
            {TABLE_STATUS_TEXT[status]}
          </Tag>
        </div>
      ),
    },
    {
      fixed: 'right',
      width: 150,
      render: (record) => {
        return (
          <Button
            className="bg-green-500 p-2"
            prefix={<ZingIcon.IconSettings />}
            onClick={() =>
              setExpandedRowKeys(
                expandedRowKeys.includes(record.id)
                  ? expandedRowKeys.filter((k) => k !== record.id)
                  : [...expandedRowKeys, record.id],
              )
            }
          >
            Cloud Control
          </Button>
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading || isLoadingUpdateStatus || isLoadingDelete}
      expandable={{
        expandedRowKeys,
        expandIconColumnIndex: -1,
        expandedRowRender: (record) => (
          <TableRowExpand
            record={record}
            handleUpdateStatus={handleUpdateStatus}
            handleDelete={handleDelete}
          />
        ),
      }}
      className="[&_.tera-table-expanded-row-fixed]:px-0 [&_.tera-table-cell]:align-middle"
      rowClassName={(_, index) =>
        index % 2 === 0 ? '!bg-white' : '!bg-[#f9fafb]'
      }
      {...props}
    />
  );
};

export default Table;
