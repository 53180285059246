import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageWarning } from '_common/constants/message';
import FormTera from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Spin,
  getQueryParams,
} from 'tera-dls';
import CloudVPSAPI from '../../apis';
import { SERVICE_CLOUD_VPS_URL } from '../../url';
import FormCreateUpdate from './components/FormCreateUpdate';
import FormSummary from './components/FormSummary';

const CreateUpdatePage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      datacenterProduct: '',
      product: '',
      locationId: '',
      osId: '',
      period: 'monthly',
      domain: null,
      isAutoRenew: 1,
      userName: 'root',
    },
  });
  const { isDirty } = form.formState;
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: () => navigate(SERVICE_CLOUD_VPS_URL.list.path),
            })
          : navigate(SERVICE_CLOUD_VPS_URL.list.path),
    },
    {
      title: `${id ? 'Sửa' : 'Thêm'} Cloud VPS`,
    },
  ];

  const {
    data: dataDetails,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => CloudVPSAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
      onSuccess: (res) =>
        form.reset({
          ...form.getValues(),
          product: res?.product,
          locationId: res?.locationId,
          osId: res?.osId,
        }),
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const countrySelected = useMemo(
    () => (id ? dataDetails?.countryCode : queryParams.country),
    [queryParams.country, dataDetails?.countryCode],
  );

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <Spin spinning={!_.isNil(id) && isLoadingDetail}>
        {id && (_.isEmpty(dataDetails) || isErrorDetail) ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex-1 flex flex-col">
            <FormCreateUpdate
              form={form}
              countryCode={countrySelected}
              dataDetails={dataDetails}
            />
            {form.watch('product') && <FormSummary form={form} />}
          </FormTera>
        )}
      </Spin>
    </div>
  );
};

export default CreateUpdatePage;
