import { ReactComponent as IconBank } from 'styles/images/Icons/bank.svg';
import { ReactComponent as IconEWallet } from 'styles/images/Icons/e-wallet.svg';
import { ReactComponent as IconStatementBank } from 'styles/images/Icons/statement-bank.svg';
import {
  formatCurrency,
  formatNumber,
  PencilSquareOutlined,
  QrCodeOutlined,
  TrashOutlined,
} from 'tera-dls';
import { PAYMENT_TYPE_ENUM, PaymentType } from '../../interfaces';
import customTwMerge from 'tailwind-merge.config';

export type CardRightProps = {
  onDelete: () => void;
  onEdit: () => void;
  onOpenStatement: () => void;
  onShowQrCode: () => void;
  display: boolean;
  availableBalance: number;
  transactionCount: number;
  totalDeposit: number;
  totalWithdrawal: number;
  paymentType: PaymentType;
};
const CardRight = ({
  onEdit,
  onOpenStatement,
  onDelete,
  display,
  paymentType,
  availableBalance,
  transactionCount,
  totalDeposit,
  totalWithdrawal,
  onShowQrCode,
}: CardRightProps) => {
  return (
    <div
      className={customTwMerge(
        'rounded-2xl z-10 absolute w-[calc(100%-30px)] left-0 h-full',
        paymentType === PAYMENT_TYPE_ENUM.BANK && 'bg-blue-600',
        paymentType === PAYMENT_TYPE_ENUM.E_WALLET && 'bg-pink-400',
        display && 'shadow-[4px_0_6px_0_rgba(0,0,0,0.25)]',
      )}
    >
      <div className="w-1/2 absolute right-0 px-4 py-3 h-full overflow-hidden">
        <div className="absolute top-[70px] left-[-69px] w-[206px] h-[206px] bg-transparent border-2 border-[#D4DDEB] border-opacity-60 rounded-full" />
        <div className="flex justify-end gap-2.5">
          {paymentType === PAYMENT_TYPE_ENUM.BANK && (
            <QrCodeOutlined
              className="w-5 h-5 text-white cursor-pointer"
              onClick={onShowQrCode}
            />
          )}
          <TrashOutlined
            className="w-5 h-5 text-red-500 cursor-pointer"
            onClick={onDelete}
          />
          <PencilSquareOutlined
            className="w-5 h-5 text-green-400 cursor-pointer"
            onClick={onEdit}
          />
        </div>

        <div className="flex gap-x-5 justify-end mt-6">
          <div className="bg-stone-50 rounded-full w-10 h-10 flex items-center justify-center">
            {paymentType === PAYMENT_TYPE_ENUM.BANK ? (
              <i className="w-6 h-6 stroke-blue-600">
                <IconBank />
              </i>
            ) : (
              <i className="w-6 h-6 fill-yellow-800">
                <IconEWallet />
              </i>
            )}
          </div>
          <div className="space-y-1">
            <p>
              <span className="text-blue-100">Số dư khả dụng: </span>
              <span className="text-base text-green-300">
                {formatCurrency(availableBalance)}
              </span>
            </p>
            <p>
              <span className="text-blue-100">Số lần giao dịch: </span>
              <span className="text-base text-green-300">
                {formatNumber(transactionCount)}
              </span>
            </p>
            <p>
              <span className="text-blue-100">Tổng thu: </span>
              <span className="text-base text-white">
                {formatCurrency(totalDeposit)}
              </span>
            </p>
            <p>
              <span className="text-blue-100">Tổng rút: </span>
              <span className="text-base text-white">
                {formatCurrency(totalWithdrawal)}
              </span>
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2.5 w-[84px] absolute bottom-7 left-6">
          <button
            onClick={onOpenStatement}
            type="button"
            className="flex items-center gap-1 bg-blue-400 rounded-full text-white pl-3 py-2"
          >
            <i className="w-3">
              <IconStatementBank />
            </i>
            Sao kê
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardRight;
